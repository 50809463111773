<template>
  <div class="supply-graph-legend">
    <p>{{ minDate }}</p>
    <p>{{ maxDate }}</p>
  </div>
</template>

<script>
export default {
  name: 'SupplyGraphLegend',

  props: [
    'minDate',
    'maxDate'
  ]
}
</script>

<style lang="scss" scoped>

.supply-graph-legend {
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    font-size: 12px;
    color: var(--neutral-7);
  }
}

</style>
