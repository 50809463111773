<template>
  <div class="miners">
    <SectionTitle
      tag="Miners"
      :title="title"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="stats">
      <SmallInfoBox
        icon="tx-per-block"
        title="TX per block"
        description="The average number of transactions per block&nbsp;today."
        :stat="transactionsPerBlock"
      />
      <SmallInfoBox
        icon="tx-per-second"
        title="TX per Second"
        description="The average number of transactions per second&nbsp;today."
        :stat="transactionsPerSecond"
      />
      <SmallInfoBox
        icon="hash-rate"
        title="Hash Rate"
        description="The number of attempts per second by miners to confirm a new&nbsp;block."
        :stat="hashrate"
      />
      <SmallInfoBox
        v-if="false"
        icon="difficulty"
        title="Difficulty"
        description=""
        :stat="difficulty"
      />
      <SmallInfoBox
        v-if="false"
        icon="difficulty-adjustment"
        title="Difficulty adjustment"
        description="How long until the difficulty of mining a block is adjusted&nbsp;again."
        :stat="difficultyAdjustment"
      />
      <SmallInfoBox
        icon="average-fee"
        title="Median transaction Fee"
        description="Typical fee paid per transaction in the last 24&nbsp;hours."
        :stat="medianTransactionFee"
      />
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import SmallInfoBox from '@/components/SmallInfoBox.vue'
import SectionTip from '@/components/SectionTip.vue'

import { DateTime } from "luxon"

export default {
  name: 'Miners',
  
  components: {
    SectionTitle,
    SmallInfoBox,
    SectionTip
  },

  props: [
    'info'
  ],

  data() {
    const sectionTip = {
      title: 'Bitcoin mining',
      description: `
        <p>Add a description...</p>
      `
    }

    return {
      sectionTip
    }
  },

  computed: {
    title() {
      const count = Tools.op(this.info, 'secondary.minutes_between_blocks')
      const formattedCount = Math.round(count*100)/100

      return 'A new block of transactions is confirmed about every '+formattedCount+' minutes.'
    },

    transactionsPerSecond() {
      const transactionsPerDay = Tools.op(this.info, 'stats.transactions_24h')
      const result = transactionsPerDay / 24 / 60 / 60
      const formattedResult = Math.round(result * 100) / 100
      return formattedResult
    },

    transactionsPerBlock() {
      const transactionsPerDay = Tools.op(this.info, 'stats.transactions_24h')
      const blocksPerDay = Tools.op(this.info, 'stats.blocks_24h')
      const result = transactionsPerDay / blocksPerDay
      const formattedResult = Math.round(result)
      return Accounting.formatNumber(formattedResult)
    },

    difficulty() {
      const result = Tools.op(this.info, 'stats.difficulty')
      const formattedResult = Accounting.formatNumber(result)
      return formattedResult
    },

    difficultyAdjustment() {
      const result = Tools.op(this.info, 'stats.next_retarget_time_estimate')
      const formattedResult = DateTime.fromSQL(result)
      return formattedResult.toRelative()
    },

    hashrate() {
      const units = [
        ['', ''],
        ['Kilo', 'K', 'Thousand'], // 3
        ['Mega', 'M', 'Million'], // 6,
        ['Giga', 'G', 'Billion'], // 9
        ['Tera', 'T', 'Trillion'], // 12
        ['Peta', 'P', 'Quadrillion'], // 15
        ['Exa', 'E', 'Quintillion'], // 18
        ['Zetta', 'Z', 'Sextillion'], // 21
        ['Yotta', 'Y', 'Septillion'] // 24
      ]

      const result = parseInt(Tools.op(this.info, 'stats.hashrate_24h'))
      const unitIndex = Math.floor(((result+'').length - 1) / 3)

      const unit = units[unitIndex]

      const unitResult = result / Math.pow(10, unitIndex*3)

      const formattedResult = Math.round(unitResult) + ' ' + unit[2]
      return formattedResult
    },

    averageTransactionFee() {
      const count = Tools.op(this.info, 'stats.average_transaction_fee_24h')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount+' SATS'
    },

    medianTransactionFee() {
      const count = Tools.op(this.info, 'stats.median_transaction_fee_24h')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount+' SATS'
    }
  },

  methods: {
    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.miners {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;

  .stats {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @include r('padding-left', 0, 150);
    @include r('padding-right', 0, 150);

    > div {
      flex-basis: 45%;
      flex-grow: 1;
    }
  }
}

</style>
