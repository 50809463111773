<template>
  <div class="supply">
    <SectionTitle
      tag="Supply"
      :title="title"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="stats">
      <SmallInfoBox
        icon="average-blocktime"
        title="Average block time"
        description="How often new bitcoin are&nbsp;generated."
        :stat="averageBlocktime"
      />
      <SmallInfoBox
        icon="minting"
        title="Block reward"
        description="The number of new bitcoin generated per&nbsp;block."
        :stat="blockReward"
      />
      <SmallInfoBox
        v-if="false"
        icon="inflation-rate"
        title="Inflation rate"
        description="The percentage of new bitcoin generated annually based on the currrent&nbsp;supply."
        :stat="inflationRate"
      />
      <SmallInfoBox
        icon="halvening"
        title="Next Halvening"
        description="Inflation is cut in half every four&nbsp;years."
        :stat="halvening"
      />
      <SmallInfoBox
        icon="max-supply"
        title="Maximum"
        description="There will never be more than 21 million&nbsp;bitcoin."
        stat="21,000,000"
      />
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'

import SectionTitle from '@/components/SectionTitle.vue'
import SmallInfoBox from '@/components/SmallInfoBox.vue'
import SectionTip from '@/components/SectionTip.vue'

import { DateTime } from "luxon"

export default {
  name: 'Supply',
  
  components: {
    SectionTitle,
    SmallInfoBox,
    SectionTip
  },

  props: [
    'info'
  ],

  data() {
    const sectionTip = {
      title: 'Bitcoin supply',
      description: `
        <p>Add a description...</p>
      `
    }

    return {
      sectionTip
    }
  },

  computed: {
    title() {
      // const satoshiSupply = Tools.op(this.info, 'stats.circulation')
      // const bitcoinSupply = Math.round(satoshiSupply / 100000000)
      // const formattedSupply = Accounting.formatNumber(bitcoinSupply)
      // return 'There are '+formattedSupply+' bitcoin in circulation.'

      return 'The bitcoin inflation rate is '+this.inflationRate+'.'
    },

    inflation() {
      const count = Tools.op(this.info, 'stats.inflation_24h')
      const formattedCount = count / 100000000
      return formattedCount
    },

    inflationRate() {
      let result
      const currentReward = Tools.op(this.info, 'halvening.current_reward') / 100000000

      const satoshiSupply = Tools.op(this.info, 'stats.circulation')
      const bitcoinSupply = Math.round(satoshiSupply / 100000000)

      const halveningTime = Tools.op(this.info, 'halvening.halvening_time')
      const halveningTimeDate = new Date(halveningTime)

      const nowDate = new Date()
      const dateDelta = halveningTimeDate - nowDate;

      const dateDeltaYears = dateDelta / 365 / 24 / 60 / 60 / 1000

      if(dateDeltaYears < 1) {
        const daysWithHigherReward = Math.round(dateDeltaYears * 365)
        const daysWithLowerReward = Math.round((1 - dateDeltaYears) * 365)

        result = (daysWithHigherReward + daysWithLowerReward/2)
      } else {
        result = 365
      }

      result = result * currentReward * 24 * 6 / bitcoinSupply

      return Math.round(result*10000)/100 + '%'
    },

    halvening() {
      const result = Tools.op(this.info, 'halvening.halvening_time')
      const formattedResult = DateTime.fromSQL(result)
      return formattedResult.toRelative()
    },

    averageBlocktime() {
      const count = Tools.op(this.info, 'secondary.minutes_between_blocks')
      const formattedCount = Math.round(count*100)/100
      return formattedCount + ' minutes'
    },

    blockReward() {
      const count = Tools.op(this.info, 'halvening.current_reward')
      const formattedCount = Math.round(count/1000000)/100
      return formattedCount + ' bitcoin'
    }
  },

  methods: {
    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.supply {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;

  .stats {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @include r('padding-left', 0, 150);
    @include r('padding-right', 0, 150);
  }

  @include media-query(medium-down) {
    .stats {
      > div {
        flex-basis: 40%;
        flex-grow: 1;
      }
    }
  }

  @include media-query(large) {
    .stats {
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      > div {
        flex-basis: 40%;
        flex-grow: 1;
      }
    }
  }
}

</style>
