
export default {

  op: function(thing, find, fallback) {
      if(!thing) {
          console.log('gbks.tools.op no thing', thing, find, fallback);
          return fallback ? fallback : null;
      }

      if(!find) {
          console.log('gbks.tools.op no find', thing, find, fallback);
          return fallback ? fallback : null;
      }

      var result = thing;

      var bits = find.split('.');
      var i=0, length=bits.length, bit;
      for(; i<length; i++) {
          bit = bits[i];

          if(result[bit]) {
              result = result[bit];
          } else {
              result = fallback === undefined ? null :  fallback;
              break;
          }
      }

      return result;
  },

  template: function(template, values) {
    let result = template

    for(let i in values) {
      result = template.split('{'+i+'}').join(values[i])
    }

    return result
  },

  log: function() {
      if(this.log) {
          var args = (arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments));
          args.splice(0, 0, this.id);
          console.log.apply(null, args);
      }
  },

  slug: function(text, separator) {
      text = text.toString().toLowerCase().trim();

      const sets = [
          {to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]'},
          {to: 'c', from: '[ÇĆĈČ]'},
          {to: 'd', from: '[ÐĎĐÞ]'},
          {to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]'},
          {to: 'g', from: '[ĜĞĢǴ]'},
          {to: 'h', from: '[ĤḦ]'},
          {to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]'},
          {to: 'j', from: '[Ĵ]'},
          {to: 'ij', from: '[Ĳ]'},
          {to: 'k', from: '[Ķ]'},
          {to: 'l', from: '[ĹĻĽŁ]'},
          {to: 'm', from: '[Ḿ]'},
          {to: 'n', from: '[ÑŃŅŇ]'},
          {to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]'},
          {to: 'oe', from: '[Œ]'},
          {to: 'p', from: '[ṕ]'},
          {to: 'r', from: '[ŔŖŘ]'},
          {to: 's', from: '[ßŚŜŞŠ]'},
          {to: 't', from: '[ŢŤ]'},
          {to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]'},
          {to: 'w', from: '[ẂŴẀẄ]'},
          {to: 'x', from: '[ẍ]'},
          {to: 'y', from: '[ÝŶŸỲỴỶỸ]'},
          {to: 'z', from: '[ŹŻŽ]'},
          {to: '-', from: '[·/_,:;\']'}
      ];

      sets.forEach(set => {
          text = text.replace(new RegExp(set.from,'gi'), set.to);
      });

      text = text.toString().toLowerCase()
          .replace(/\s+/g, '-')         // Replace spaces with -
          .replace(/&/g, '-and-')       // Replace & with 'and'
          .replace(/[^\w]+/g, '')     // Remove all non-word chars
          .replace(/-+/g, '-')        // Replace multiple - with single -
          .replace(/^-+/, '')           // Trim - from start of text
          .replace(/-+$/, '');          // Trim - from end of text

      if ((typeof separator !== 'undefined') && (separator !== '-')) {
          text = text.replace(/-/g, separator);
      }

      return text;
  },

  shuffleArray: function(arr) {
      var currentIndex = arr.length;
      var temporaryValue, randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = arr[currentIndex];
          arr[currentIndex] = arr[randomIndex];
          arr[randomIndex] = temporaryValue;
      }

      return arr;
  },

  /*
  data = {
      required: true,
      type: "object",
      keys: {
          name: {
              type: "string"
          }
      }
  }
  */
  compareDataStructure: function(structure, data) {
      var valid = true;

      var type =  typeof(data);

      if(type == 'object' && Array.isArray(data)) {
          type = 'array';
      }

      switch(type) {
          case 'object':
              break;
          case 'array':
              break;
          case 'number':
              break;
          case 'string':
              break;
          case 'boolean':
              break;
          case 'bigint':
              break;
          case 'function':
              break;
          case 'symbol':
              break;
          case 'undefined':
              break;
          default:
              break;
      }


      return valid;
  },

  colorizeNumber: function(percentage, green, red) {
    percentage = Math.max(0, Math.min(1, percentage));
    
    // var green = [3, 185, 108];
    if(!green) {
        green = [255, 255, 255];
    }

    if(!red) {
        red = [235, 27, 10];
    }

    var r = green[0];
    var g = green[1];
    var b = green[2];

    r += (red[0] - r) * percentage;
    g += (red[1] - g) * percentage;
    b += (red[2] - b) * percentage;

    return 'rgb('+Math.round(r)+', '+Math.round(g)+', '+Math.round(b)+')'; 
  }
}