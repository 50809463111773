<template>
  <div class="footer">
    <div class="content">
      <div class="top">
        <div class="left">
          <h3>About</h3>
          <p>The bitcoin network is a bit like a clock that runs at its own rhythm. Satoshi Nakamoto even referred to it as a timechain instead of a blockchain. The unit is based on the creation of new blocks, the blocktime, which happens roughly every 10 minutes. Blocks are linked together in an ever growing sequence, which consitutes an immutable chain of events. The goal of this site is to highlight some of the mechanisms and dynamics in the network, as well as around the network (like price, which is an external assignment).</p>
          <p>Site by <a href="https://www.germanysbestkeptsecret.com/" target="_blank" rel="me">GBKS</a>. Data via <a href="https://blockchair.com/" rel="nofollow noindex" target="_blank">blockchair.com</a>, <a href="https://www.blockchain.com/" rel="nofollow noindex" target="_blank">blockchain.com</a>, and <a href="https://1ml.com/" rel="nofollow noindex" target="_blank">1ml.com</a>.</p>
        </div>
        <div class="right">
          <h3>Further resources</h3>
          <p>Curious to dig deeper. Here are a few more useful links to dive into.</p>
          <ul>
            <li
              v-for="(item, index) in links"
              :key="index"
            >
              <a 
              :href="item.url" 
              target="_blank" 
              rel="nofollow noindex"
            >{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M50.5 99.5C77.8381 99.5 100 77.3381 100 50C100 22.6619 77.8381 0.5 50.5 0.5C23.1619 0.5 1 22.6619 1 50C1 77.3381 23.1619 99.5 50.5 99.5Z" stroke="black"/>
            <path d="M50.5 96.5C76.1812 96.5 97 75.6812 97 50C97 24.3188 76.1812 3.5 50.5 3.5C24.8188 3.5 4 24.3188 4 50C4 75.6812 24.8188 96.5 50.5 96.5Z" stroke="black"/>
            <path d="M50.5 77.5C65.6878 77.5 78 65.1878 78 50C78 34.8122 65.6878 22.5 50.5 22.5C35.3122 22.5 23 34.8122 23 50C23 65.1878 35.3122 77.5 50.5 77.5Z" stroke="black"/>
            <path d="M50.5 74.5C64.031 74.5 75 63.531 75 50C75 36.469 64.031 25.5 50.5 25.5C36.969 25.5 26 36.469 26 50C26 63.531 36.969 74.5 50.5 74.5Z" stroke="black"/>
            <path d="M86.2534 61.0811C87.0818 61.0811 87.7534 60.4095 87.7534 59.5811C87.7534 58.7526 87.0818 58.0811 86.2534 58.0811C85.425 58.0811 84.7534 58.7526 84.7534 59.5811C84.7534 60.4095 85.425 61.0811 86.2534 61.0811Z" stroke="black"/>
            <path d="M14.7446 61.0801C15.5731 61.0801 16.2446 60.4085 16.2446 59.5801C16.2446 58.7517 15.5731 58.0801 14.7446 58.0801C13.9162 58.0801 13.2446 58.7517 13.2446 59.5801C13.2446 60.4085 13.9162 61.0801 14.7446 61.0801Z" stroke="black"/>
            <path d="M63.448 45.8957C63.9581 42.1058 61.0828 40.1273 57.1408 38.7897L58.3652 33.6622L55.2487 32.9098L54.0615 37.9258C53.236 37.7308 52.4012 37.5357 51.5757 37.3685L52.7629 32.3525L49.6093 31.6001L48.385 36.7276C47.7172 36.5697 47.0401 36.4489 46.3723 36.291L42.0778 35.2599L41.2894 38.5761C41.2894 38.5761 43.6175 39.087 43.5341 39.1334C44.7955 39.4492 45.0366 40.2759 44.9903 40.9076L43.6083 46.7503C43.6917 46.7875 43.803 46.7875 43.9236 46.8711C43.803 46.8339 43.7288 46.8339 43.6083 46.7967L41.7161 54.971C41.5584 55.3611 41.1596 55.9184 40.297 55.7234C40.3341 55.7605 38.006 55.166 38.006 55.166L36.5312 58.7608L40.5938 59.7083C41.3451 59.9034 42.0964 60.0613 42.8013 60.2656L41.577 65.4396L44.6935 66.192L45.9178 61.0645C46.7897 61.2967 47.6152 61.5011 48.4036 61.6961L47.1792 66.7865L50.2957 67.5389L51.52 62.3649C56.844 63.3124 60.8231 62.8758 62.437 58.0177C63.7355 54.1443 62.3164 51.9335 59.4782 50.4751C61.5558 50.0014 63.0584 48.6545 63.448 45.8957ZM56.4359 55.9556C55.5269 59.8291 48.9879 57.8134 46.8917 57.3025L48.5056 50.4287C50.6389 50.9489 57.382 51.8963 56.4359 55.9556ZM57.2985 45.8957C56.473 49.4069 51.0285 47.7163 49.2569 47.2798L50.7595 41.0469C52.4939 41.4742 58.1704 42.2266 57.2985 45.8957Z" fill="currentColor"/>
            <path d="M25.2351 75.7891C26.3027 74.5847 26.2081 73.0516 25.1737 71.8431C23.9548 70.4189 21.9985 70.2347 20.2363 71.743C18.4768 73.2489 18.3428 75.1992 19.5731 76.6367C20.6826 77.933 22.257 78.0751 23.4277 77.336L22.5809 76.3359C21.835 76.7576 21.0291 76.5435 20.4709 75.8913C19.7145 75.0075 19.835 73.8067 21.0861 72.7359C22.3159 71.6833 23.5377 71.726 24.301 72.6177C24.8683 73.2805 24.9372 74.1209 24.383 74.7935L25.2351 75.7891Z" fill="currentColor"/>
            <path d="M31.1617 81.869C32.5368 80.0011 32.207 78.0614 30.6918 76.9459C29.1737 75.8284 27.2265 76.0917 25.8514 77.9596C24.4783 79.8247 24.8032 81.7651 26.3213 82.8826C27.8365 83.9981 29.7865 83.7369 31.1617 81.869ZM30.1092 81.0942C29.1412 82.409 27.9554 82.6368 27.013 81.943C26.0734 81.2513 25.9358 80.0493 26.9038 78.7344C27.8718 77.4195 29.0605 77.1938 30.0001 77.8855C30.9425 78.5793 31.0772 79.7793 30.1092 81.0942Z" fill="currentColor"/>
            <path d="M34.4602 79.718L31.7872 86.3774L32.9448 86.842L34.7851 82.2572L34.8469 82.282L34.8548 87.586L35.7198 87.9332L39.3887 84.1164L39.4505 84.1412L37.6141 88.7162L38.7717 89.1809L41.4447 82.5215L39.9685 81.929L36.0546 85.9609L35.9766 85.9296L35.9365 80.3106L34.4602 79.718Z" fill="currentColor"/>
            <path d="M43.0243 82.9252L42.2493 90.059L43.4894 90.1937L44.0229 85.2822L44.0891 85.2894L45.5294 90.3942L46.4559 90.4948L48.9576 85.8289L49.0238 85.8361L48.4914 90.7371L49.7314 90.8718L50.5064 83.738L48.925 83.5662L46.2456 88.5054L46.162 88.4963L44.6057 83.097L43.0243 82.9252Z" fill="currentColor"/>
            <path d="M56.6884 83.0135L57.3091 87.5507C57.4312 88.4429 56.8972 89.1666 55.9217 89.3001C54.9427 89.434 54.2374 88.8798 54.1154 87.9876L53.4947 83.4504L52.2068 83.6266L52.8422 88.2714C53.0512 89.7989 54.3397 90.6871 56.0789 90.4491C57.8111 90.2121 58.8207 89.0096 58.6118 87.4821L57.9763 82.8373L56.6884 83.0135Z" fill="currentColor"/>
            <path d="M65.1417 80.3374L63.9301 80.7886L65.6369 85.3724L65.5778 85.3944L60.7056 81.9892L59.6155 82.3951L62.1194 89.1198L63.3376 88.6662L61.6321 84.0857L61.6879 84.0649L66.5621 87.4656L67.6456 87.0622L65.1417 80.3374Z" fill="currentColor"/>
            <path d="M67.8047 78.7143L66.6777 79.362L70.2532 85.5835L71.3803 84.9357L67.8047 78.7143Z" fill="currentColor"/>
            <path d="M69.8704 78.8548L71.603 77.4702L75.4025 82.2246L76.4098 81.4196L72.6103 76.6652L74.3429 75.2806L73.6626 74.4293L69.1901 78.0035L69.8704 78.8548Z" fill="currentColor"/>
            <path d="M74.3333 73.8238L79.4653 74.6703L81.5311 76.3727L82.3556 75.3722L80.2897 73.6698L78.4778 68.7943L77.5442 69.9273L78.8777 73.3008L78.8331 73.3548L75.267 72.6908L74.3333 73.8238Z" fill="currentColor"/>
            <path d="M17.271 46.8349L17.7614 43.972C18.0388 42.3523 17.3495 41.4166 16.2478 41.2279C15.1773 41.0445 14.4186 41.6966 14.2516 42.443L14.1826 42.4311C14.1386 41.7126 13.7485 41.0842 12.8886 40.9369C11.8353 40.7564 10.9247 41.3825 10.662 42.9159L10.1982 45.6234L17.271 46.8349ZM16.4199 45.3703L14.3478 45.0153L14.5897 43.6029C14.7252 42.812 15.2927 42.4044 15.9247 42.5127C16.4876 42.6091 16.815 43.0633 16.6559 43.9923L16.4199 45.3703ZM13.4257 44.8574L11.4745 44.5232L11.6963 43.2281C11.8253 42.4752 12.2893 42.153 12.8349 42.2465C13.4565 42.353 13.757 42.9234 13.6428 43.59L13.4257 44.8574Z" fill="currentColor"/>
            <path d="M12.8275 35.2121L12.372 36.4296L19.0929 38.9438L19.5484 37.7263L12.8275 35.2121Z" fill="currentColor"/>
            <path d="M15.3464 32.0338L16.498 30.1383L21.6994 33.2983L22.3688 32.1964L17.1674 29.0364L18.319 27.1408L17.3877 26.5751L14.4151 31.468L15.3464 32.0338Z" fill="currentColor"/>
            <path d="M25.8574 20.7801C24.574 19.8089 23.0528 20.0219 21.928 21.1468C20.6025 22.4722 20.5703 24.4369 22.2104 26.0771C23.8481 27.7147 25.8029 27.6974 27.1408 26.3595C28.3473 25.153 28.3671 23.5723 27.5396 22.4623L26.6081 23.384C27.0862 24.095 26.9351 24.9151 26.3281 25.5221C25.5056 26.3447 24.299 26.3174 23.1346 25.153C21.9899 24.0083 21.9379 22.7869 22.7679 21.9569C23.3848 21.34 24.2172 21.2062 24.9308 21.7067L25.8574 20.7801Z" fill="currentColor"/>
            <path d="M35.0603 16.3432C33.9073 14.3306 31.9997 13.8486 30.3672 14.784C28.7315 15.7211 28.1857 17.6087 29.3388 19.6213C30.4901 21.6308 32.3963 22.1176 34.0319 21.1805C35.6645 20.2451 36.2134 18.3558 35.0603 16.3432ZM33.9264 16.9929C34.7381 18.4096 34.4673 19.5863 33.4519 20.1681C32.4395 20.7481 31.2844 20.3883 30.4727 18.9716C29.661 17.5549 29.9348 16.3764 30.9472 15.7964C31.9626 15.2146 33.1147 15.5762 33.9264 16.9929Z" fill="currentColor"/>
            <path d="M38.5279 11.3424L37.2925 11.7467L39.5242 18.5666L40.7596 18.1623L38.5279 11.3424Z" fill="currentColor"/>
            <path d="M48.668 9.4751L47.3839 9.62594L47.9546 14.4838L47.8919 14.4912L43.9667 10.0274L42.8114 10.1631L43.6486 17.2898L44.9396 17.1382L44.3693 12.2838L44.4285 12.2768L48.3568 16.7368L49.5052 16.6019L48.668 9.4751Z" fill="currentColor"/>
            <path d="M58.8915 17.8209C61.0018 18.3925 62.6067 17.426 63.1929 15.2615C63.7773 13.1038 62.8777 11.4663 60.8147 10.9075L58.4203 10.259L56.5444 17.1853L58.8915 17.8209ZM58.0931 16.4395L59.3809 11.6845L60.4496 11.9739C61.8565 12.3549 62.3779 13.3383 61.9483 14.9244C61.5169 16.5173 60.5687 17.11 59.1246 16.7189L58.0931 16.4395Z" fill="currentColor"/>
            <path d="M64.7128 19.8118L68.8108 22.103L69.3426 21.1519L66.3792 19.495L67.335 17.7855L70.0629 19.3107L70.5947 18.3596L67.8668 16.8344L68.8175 15.134L71.7564 16.7772L72.2882 15.8261L68.2146 13.5485L64.7128 19.8118Z" fill="currentColor"/>
            <path d="M77.4605 22.4971L78.3849 23.3397C79.1835 22.4272 78.9979 21.0965 77.8689 20.0674C76.7554 19.0525 75.3478 18.9167 74.496 19.8564C73.802 20.6177 73.9443 21.5487 74.6116 22.5694L75.0733 23.2747C75.5134 23.9414 75.7286 24.4647 75.3559 24.8787C74.9403 25.3295 74.2343 25.2407 73.6076 24.6695C73.0069 24.1219 72.7862 23.4277 73.2549 22.8356L72.3097 21.974C71.3879 23.0634 71.632 24.3715 72.8671 25.4974C74.1386 26.6563 75.4548 26.6946 76.3095 25.7622C77.1449 24.8406 76.7685 23.7295 76.1433 22.8135L75.7594 22.236C75.4188 21.7359 75.1151 21.1225 75.5239 20.6844C75.89 20.288 76.514 20.3211 77.1148 20.8687C77.6871 21.3903 77.822 22.0017 77.4605 22.4971Z" fill="currentColor"/>
            <path d="M83.2086 26.1807L82.4287 25.1408L76.6881 29.4462L77.468 30.4861L83.2086 26.1807Z" fill="currentColor"/>
            <path d="M85.7076 35.1408L86.2798 36.3352C87.4547 35.5275 87.8126 34.004 87.1268 32.5726C86.3153 30.8789 84.4618 30.2051 82.3763 31.2043C80.3287 32.1854 79.6398 34.0073 80.4952 35.7927C81.2627 37.3947 82.8424 38.0482 84.5108 37.2488L85.2881 36.8764L84.03 34.2506L83.1137 34.6896L83.8283 36.181C82.8965 36.6041 82.0167 36.2681 81.5504 35.2948C81.0311 34.211 81.4874 33.0794 82.9536 32.3769C84.4103 31.679 85.581 32.0466 86.0821 33.0925C86.4561 33.873 86.2933 34.6037 85.7076 35.1408Z" fill="currentColor"/>
            <path d="M90.8173 45.4921L90.5819 44.2208L85.7723 45.1113L85.7609 45.0493L89.9555 40.8376L89.7437 39.6938L82.6878 41.0002L82.9245 42.2784L87.7306 41.3885L87.7414 41.4471L83.5509 45.6616L83.7614 46.7985L90.8173 45.4921Z" fill="currentColor"/>
          </g>
        </svg>
        <div class="copy">
          <p>Join the</p>
          <h4>Bitcoin Design Community</h4>
          <a href="https://bitcoin.design" target="_blank">Check it out<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8.01251L18.7889 8" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.0127 15L19.0002 8L12.0127 1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  data() {
    const links = [
      {
        name: 'Bitcoin.org',
        url: 'https://www.bitcoin.org'
      },
      {
        name: 'Hello Bitcoin',
        url: 'https://hellobitco.in/'
      },
      {
        name: 'Bitcoin intro',
        url: 'https://bitcoin-intro.com/'
      },
      {
        name: 'Bitcoin only',
        url: 'https://bitcoin-only.com/'
      },
      {
        name: 'Bitcoin Resources by Lopp',
        url: 'https://www.lopp.net/bitcoin-information.html'
      }
    ]

    return { links }
  }
}
</script>

<style lang="scss" scoped>

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--neutral-4);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--neutral-7);
      text-align: left;

      a {
        color: var(--front);

        &:hover {
          color: $primary;
          border-bottom: 1px dashed $primary;
        }
      }
    }

    .top {
      display: flex;
      gap: 40px;
      padding: 20px 20px;
      @include r('padding-top', 30, 40);
      @include r('padding-bottom', 30, 40);
      @include r('padding-left', 15, 40);
      @include r('padding-right', 15, 40);      
      border-bottom: 1px solid var(--neutral-4);

      .left,
      .right {
        h3 {
          & + p {
            margin-top: 5px;
          }
        }

        p {
          & + p,
          & + ul {
            margin-top: 5px;
          }
        }

        ul {
          list-style-type: disc;
          padding-left: 20px;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      flex-basis: 100%;
      flex-grow: 1;
      @include r('padding-top', 30, 40);
      @include r('padding-bottom', 30, 40);
      @include r('padding-left', 15, 40);
      @include r('padding-right', 15, 40); 

      .copy {
        p {

        }

        h4 {
          font-size: 24px;
          line-height: 1.1;
        }

        a {
          display: inline-block;
          margin-top: 15px;
          padding: 7px 12px;
          background-color: var(--front);
          color: var(--back);
          font-weight: 500;
          transition: all 150ms $ease;

          svg {
            width: 15px;
            height: 12px;
            margin-left: 10px;
            vertical-align: middle;
            transform: translateY(-1px);
            transition: all 150ms $ease;
          }

          &:hover {
            background-color: $primary;

            svg {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  @include media-query(medium-down) {
    .content {
      flex-direction: column;

      .top {
        flex-direction: column;
      }

      .bottom {
        svg {
          width: 75px;
          height: 75px;
        }

        .copy {
          h4 {
            font-size: 21px;
            font-weight: 500;
          }

          a {
            font-size: 16px;
          }
        }
      }
    }
  }

  @include media-query(large) {
    .content {
      flex-direction: column;

      .top {
        justify-content: center;
        
        .left,
        .right {
          flex-basis: 600px;
          flex-shrink: 1;
        }
      }
    }
  }
}

</style>
