<template>
  <div 
    ref="canvas"
    class="supply-graph"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mousemove="onMouseMove"
  >
    <svg
      v-if="graphWidth && graphHeight"
      :width="graphWidth" 
      :height="graphHeight" 
      :viewBox="graphViewbox" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path class="chart" :d="path" />
      <path class="highlighted-chart" :d="highlightedPath" />
      <path v-if="!hovering" class="now" :d="currentDatePath" />
      <path v-if="hovering" class="line" :d="hoverPath" />
      <circle
        :cx="circleX"
        :cy="circleY"
        :r="circleRadius"
      />
    </svg>
    <p
      :style="hoverStyle"
      v-html="hoverText"
    />
  </div>
</template>

<script>
import Accounting from '@/general/Accounting.js'

export default {
  name: 'SupplyGraph',

  props: [
    'info',
    'minDate',
    'maxDate'
  ],

  data() {
    /*
    
    0 at 2009-01-03 19:15
    1 at 2009-01-09 03:54
    32486 first block in 2010
    100406 first block in 2011
    160030 first block in 2012
    214554 first block in 2013
    277990 first block in 2014
    336849 first block in 2015
    391177 first block in 2016
    446027 first block in 2017
    501951 first block in 2018 (55924)
    556453 first block in 2019 (54502)
    610682 first block in 2020 (54229)
    663905 first block in 2021 (53223)
    716591 first block in 2022 (52686 blocks)

     */

     const currentGraphItems = [
      {
        block: 0,
        date: '2009-01-09 03:54'
      },
      {
        block: 32486,
        date: '2010-01-01 00:05'
      },
      {
        block: 100406,
        date: '2011-01-01 00:19'
      },
      {
        block: 160030,
        date: '2012-01-01 00:00'
      },
      {
        block: 214554,
        date: '2013-01-01 00:00'
      },
      {
        block: 277990,
        date: '2014-01-01 00:01'
      },
      {
        block: 336849,
        date: '2015-01-01 00:03'
      },
      {
        block: 391177,
        date: '2016-01-01 00:01'
      },
      {
        block: 446027,
        date: '2017-01-01 00:02'
      },
      {
        block: 501951,
        date: '2018-01-01 00:04'
      },
      {
        block: 556453,
        date: '2019-01-01 00:11'
      },
      {
        block: 610682,
        date: '2020-01-01 00:02'
      },
      {
        block: 663905,
        date: '2021-01-01 00:10'
      },
      {
        block: 716591,
        date: '2022-01-01 00:17'
      }
    ]

    let item, i
    for(i=0; i<currentGraphItems.length; i++) {
      item = currentGraphItems[i]

      item.year = new Date(item.date).getFullYear()
    }

    const graphItems = currentGraphItems.slice()

    let startYear = 2023
    let endYear = this.maxDate
    let startBlock = 716591
    while(startYear <= endYear) {
      startBlock += 6 * 24 * 365

      graphItems.push({
        block: startBlock,
        date: startYear + '-01-01 00:00',
        year: startYear
      })

      startYear++
    }

    for(i=0; i<graphItems.length; i++) {
      item = graphItems[i]

      item.supply = this.supplyAtBlock(item.block)
    }

    return {
      currentGraphItems,
      graphItems,
      startYear: 2009,
      endYear,
      graphWidth: null,
      graphHeight: null,
      circleRadius: 5,
      hovering: false,
      hoverX: null,
      hoverY: null
    }
  },

  mounted() {
    this.updateCanvasSize()

    window.addEventListener('resize', this.onResize.bind(this))
  },

  computed: {
    graphViewbox() {
      return '0 0 '+this.graphWidth+' '+this.graphHeight
    },

    hoverXPercentage() {
      const firstYear = this.graphItems[0].year;
      const lastYear = this.graphItems[this.graphItems.length-1].year
      const yearCount = lastYear - firstYear
      const rangePercentage = Math.round(this.hoverX/this.graphWidth*yearCount)/yearCount

      return rangePercentage
    },

    currentDateXPercentage() {
      const firstYear = this.graphItems[0].year;
      const lastYear = this.graphItems[this.graphItems.length-1].year
      const nowDate = new Date()
      const yearPercentage = nowDate.getMonth() / 12
      const yearCount = lastYear - firstYear
      const rangePercentage = (nowDate.getFullYear() - firstYear)/yearCount + yearPercentage*(1/yearCount)

      return rangePercentage
    },

    currentDatePath() {
      const x = this.currentDateXPercentage * this.graphWidth
      const y = 0
      const y2 = this.graphHeight
      return 'M'+x+' '+y+' L'+x+' '+y2
    },

    path() {
      const bits = []

      let item, x, y
      for(let i=0; i<this.graphItems.length; i++) {
        item = this.graphItems[i]

        x = 1 + i/this.graphItems.length * (this.graphWidth - 2)
        y = 1 + (1 - item.supply / 21000000) * (this.graphHeight - 2)

        if(i == 0) {
          bits.push('M'+x+' '+y)
        } else {
          bits.push('L'+x+' '+y)
        }

        // console.log('i', i, item.supply, x, y)
      } 

      // bits.push('Z')

      return bits.join(' ')
    },

    highlightedPath() {
      const bits = []

      let item, x, y
      for(let i=0; i<this.currentGraphItems.length; i++) {
        item = this.currentGraphItems[i]

        x = 1 + i/this.graphItems.length * (this.graphWidth - 2)
        y = 1 + (1 - item.supply / 21000000) * (this.graphHeight - 2)

        if(i == 0) {
          bits.push('M'+x+' '+y)
        } else {
          bits.push('L'+x+' '+y)
        }

        // console.log('i', i, item.supply, x, y)
      } 

      // bits.push('Z')

      return bits.join(' ')
    },

    circleX() {
      if(this.hovering) {
        return this.hoverXPercentage * this.graphWidth
      } else {
        return this.currentDateXPercentage * this.graphWidth
      }
    },

    circleY() {
      if(this.hovering) {
        const item = this.itemAtGraphX(this.hoverX)
        return this.supplyToGraphY(item.supply)
      } else {
        const item = this.currentGraphItems[this.currentGraphItems.length - 1]
        return this.supplyToGraphY(item.supply)
      }
    },

    hoverPath() {
      const bits = []

      // const percentage = (this.hoverX-1)/(this.graphWidth-2)
      // const left = 1 + Math.round(percentage*this.graphItems.length)/this.graphItems.length*(this.graphWidth-2)

      const left = this.hoverXPercentage * (this.graphWidth - 2)

      bits.push('M'+left+' 1')
      bits.push('L'+left+' '+(this.graphHeight-2))

      return bits.join(' ')
    },

    hoverText() {
      const itemCount = this.graphItems.length
      const percentage = this.hoverXPercentage
      
      let priceIndex = Math.round(itemCount*percentage)
      priceIndex = Math.min(itemCount-1, Math.max(priceIndex, 0))

      let result = ''

      if(priceIndex) {
        const priceItem = this.graphItems[priceIndex]

        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
        const d = new Date(priceItem.date)
        const formattedDate = months[d.getMonth()]+' '+d.getDate()+', '+d.getFullYear()

        result = formattedDate+'<br/><span>'+Accounting.formatNumber(priceItem.supply)+'</span>'
      }

      return result
    },

    hoverStyle() {
      const itemCount = this.graphItems.length
      let percentage = this.hoverX/this.graphWidth

      percentage = Math.max(0, Math.min(1, percentage))

      const left = Math.round(percentage*itemCount)/itemCount*100

      // console.log('p', itemCount, percentage, left)

      return {
        left: left+'%',
        top: (this.hoverY - 20)+'px'
      }
    }
  },

  methods: {
    supplyAtBlock(block) {
      let result

      if(block >= 33 * 210000) {
        result = 20999999.9769
      } else {
        let reward = 50e8
        let supply = 0
        const y = 210000  // reward changes all y blocks
        while(block > y - 1) {
          supply = supply + y * reward
          reward = (reward / 2.0)
          block = block - y
        }
        supply = supply + block * reward
        result = (supply + reward) / 1e8
      }

      return result
    },

    updateCanvasSize() {
      const canvas = this.$refs.canvas
      this.graphWidth = canvas.offsetWidth
      this.graphHeight = canvas.offsetHeight
    },

    supplyToGraphY(supply) {
      return 1 + (1 - supply / 21000000) * (this.graphHeight - 2)
    },

    itemAtGraphX(x) {
      const percentage = (x - 1)/(this.graphWidth - 2)
      const itemCount = this.graphItems.length
      const itemIndex = Math.round(percentage * (itemCount - 1))
      return this.graphItems[itemIndex]
    },

    onResize() {
      this.updateCanvasSize()
    },

    onMouseEnter(event) {
      this.hovering = true
      this.hoverX = event.offsetX
      this.hoverY = event.offsetY
    },
    
    onMouseLeave(event) {
      this.hovering = false
      this.hoverX = event.offsetX
      this.hoverY = event.offsetY
    },
    
    onMouseMove(event) {
      this.hoverX = event.offsetX
      this.hoverY = event.offsetY
    }
  }
}
</script>

<style lang="scss" scoped>

.supply-graph {
  position: relative;
  border-bottom: 1px solid rgba(var(--frontRGB), 0.2);
  border-left: 1px solid rgba(var(--frontRGB), 0.2);
  width: 100%;
  max-width: 600px;
  font-size: 0;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    overflow: visible;

    path {
      fill: none;

      &.chart {
        stroke: var(--neutral-4);
      }

      &.highlighted-chart {
        stroke: $primary;
      }

      &.line,
      &.now {
        fill: none;
        stroke: var(--neutral-6);
        stroke-width: 1px;
        stroke-linecap: round;
        stroke-dasharray: 4;
      }

      &.line {
        stroke: $primary;
      }
    }

    circle {
      fill: var(--back);
      stroke: $primary;
      stroke-width: 1px;
    }
  }

  p {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    background-color: var(--back);
    border-radius: 2px;
    padding: 0 2px;
    text-align: center;
    color: var(--neutral-6);
    font-size: 14px;
    line-height: 1.2;
    white-space: nowrap;

    &::v-deep span {
      color: var(--front);
    }
  }

  @include media-query(medium-down) {
    height: 200px;
  }

  @include media-query(large) {
    flex-basis: 200px;
    flex-grow: 1;
  }
}

</style>
