<template>
  <div 
    ref="canvas"
    class="graph" 
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @mousemove="onMouseMove"
  >
    <svg
      v-if="graphWidth && graphHeight"
      :width="graphWidth" 
      :height="graphHeight" 
      :viewBox="graphViewbox" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path class="chart" :d="path" />
      <path v-if="hovering" class="line" :d="hoverPath" />
    </svg>
    <p
      v-if="hovering"
      :style="hoverStyle"
      v-html="hoverText"
    />
  </div>
</template>

<script>
import Accounting from '@/general/Accounting.js'

export default {
  name: 'Graph',

  props: [
    'info',
    'daily'
  ],

  data() {
    return {
      prop: 'lc',
      hovering: false,
      hoverX: null,
      hoverY: null,
      graphWidth: null,
      graphHeight: null
    }
  },

  mounted() {
    this.updateCanvasSize()

    window.addEventListener('resize', this.onResize.bind(this))
  },

  computed: {
    graphViewbox() {
      return '0 0 '+this.graphWidth+' '+this.graphHeight
    },

    minValue() {
      let result, item, price
      for(let day in this.daily) {
        item = this.daily[day]
        price = item[this.prop]

        if(!result || price < result) {
          result = price
        }
      }
      return result
    },

    maxValue() {
      let result, item, price
      for(let day in this.daily) {
        item = this.daily[day]
        price = item[this.prop]

        if(!result || price > result) {
          result = price
        }
      }
      return result
    },

    path() {
      const bits = []

      const maxValue = this.maxValue
      const minValue = this.minValue

      const itemCount = Object.keys(this.daily).length

      let item, price, x, y
      for(let day in this.daily) {
        item = this.daily[day]
        price = item[this.prop]

        x = 1 + bits.length/(itemCount-1) * (this.graphWidth - 2)
        y = 1 + (1- (price - minValue) / (maxValue - minValue)) * (this.graphHeight - 2)

        if(bits.length == 0) {
          bits.push('M'+x+' '+y)
        } else {
          bits.push('L'+x+' '+y)
        }

        // console.log('i', i, item.supply, x, y)
      } 

      // bits.push('Z')

      return bits.join(' ')
    },

    hoverPath() {
      const bits = []

      const dailyKeys = Object.keys(this.daily)
      const dailyKeysLength = dailyKeys.length-1
      const percentage = (this.hoverX-1)/(this.graphWidth-2)

      const left = 1 + Math.round(percentage*dailyKeysLength)/dailyKeysLength*(this.graphWidth-2)

      bits.push('M'+left+' 1')
      bits.push('L'+left+' '+(this.graphHeight-2))

      return bits.join(' ')
    },

    hoverText() {
      const dailyKeys = Object.keys(this.daily)
      const percentage = this.hoverX/this.graphWidth
      
      let priceIndex = Math.round(dailyKeys.length*percentage)
      priceIndex = Math.min(dailyKeys.length-1, Math.max(priceIndex, 0))

      const priceDate = dailyKeys[priceIndex]
      const priceItem = this.daily[priceDate]

      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const d = new Date(priceDate)
      const formattedDate = months[d.getMonth()]+'&nbsp;'+d.getDate()

      const bitcoin = priceItem[this.prop] / 100000000
      return formattedDate+'<br/><span>'+Accounting.formatNumber(bitcoin)+'&nbsp;bitcoin</span>'
    },

    hoverStyle() {
      const dailyKeys = Object.keys(this.daily)
      const percentage = this.hoverX/this.graphWidth

      const left = Math.round(percentage*dailyKeys.length)/dailyKeys.length*100

      return {
        left: left+'%',
        top: (this.hoverY - 20)+'px'
      }
    }
  },

  methods: {
    onMouseEnter(event) {
      this.hovering = true
      this.hoverX = event.offsetX
      this.hoverY = event.offsetY
    },
    
    onMouseLeave(event) {
      this.hovering = false
      this.hoverX = event.offsetX
      this.hoverY = event.offsetY
    },
    
    onMouseMove(event) {
      this.hoverX = event.offsetX
      this.hoverY = event.offsetY
    },

    updateCanvasSize() {
      const canvas = this.$refs.canvas
      if(canvas) {
        this.graphWidth = canvas.offsetWidth
        this.graphHeight = canvas.offsetHeight
      }
    },

    onResize() {
      this.updateCanvasSize()
    }
  }
}
</script>

<style lang="scss" scoped>

.graph {
  position: relative;
  width: 100%;
  padding: 0;
  line-height: 1;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    
    path {
      &.chart {
        fill: none;
        stroke: $primary;
        stroke-width: 3px;
        stroke-linecap: round;
      }

      &.line {
        fill: none;
        stroke: var(--neutral-6);
        stroke-width: 1px;
        stroke-linecap: round;
        stroke-dasharray: 4;
      }
    }
  }

  p {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    background-color: var(--back);
    border-radius: 2px;
    padding: 0 2px;
    text-align: center;
    color: var(--neutral-6);
    line-height: 1.2;
    // transition: left 50ms linear;

    &::v-deep span {
      color: var(--front);
    }
  }

  @include media-query(medium-down) {
    flex-basis: 200px;
    flex-grow: 0;
  }

  @include media-query(large) {
    flex-basis: 100px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

</style>
