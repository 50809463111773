<template>
  <div :class="classObject" :style="styleObject">
    <div class="height">
      <p>Block</p>
      <p><b>{{ blockHeight }}</b></p>
    </div>
    <ol>
      <li>
        <p>Confirmed</p>
        <p>{{ blockTime }}</p>
      </li>
      <li>
        <p>Transactions</p>
        <p>{{ transactions }}</p>
      </li>
      <li>
        <p>Average fee</p>
        <p>{{ averageFee }}</p>
      </li>
    </ol>
  </div>
</template>

<script>
import Accounting from '@/general/Accounting.js'

import { DateTime } from "luxon"

export default {
  name: 'Block',

  props: [
    'info',
    'index',
    'activeIndex'
  ],

  computed: {
    classObject() {
      const c = ['block']

      if(this.activeIndex > this.index) {
        c.push('-inactive')
      }

      return c.join(' ')
    },

    styleObject() {
      const s = {}

      const transform = []
      let opacity = 1

      if(this.index > 0) {
        const scale = 1 - this.index * 0.1

        transform.push('translateX(-50%)')
        transform.push('scale('+scale+', '+scale+')')

        s.top = -this.index * 10 + 'px'
        opacity = 1 - (this.index - this.activeIndex) * 0.2
      }

      if(this.activeIndex > this.index) {
        transform.push('translateY(30%)')
        opacity = 0
      }

      s.transform = transform.join(' ')
      s.opacity = opacity
      s.zIndex = (10 - this.index)

      return s;
    },

    blockHeight() {
      const height = this.info.blockHeight
      const formattedHeight = Accounting.formatNumber(height)
      return formattedHeight
    },

    blockTime() {
      let result = ''

      if(this.info.blockTime) {
        result = DateTime.fromSQL(this.info.blockTime, { zone: 'UTC' }).toRelative()
      }
      
      return result
    },

    transactions() {
      let result = ''

      if(this.info.transactions) {
        result = Accounting.formatNumber(this.info.transactions)
      }

      return result
    },

    averageFee() {
      let result = ''

      if(this.info.feeTotal) {
        const fee = Math.round(this.info.feeTotal/this.info.transactions)
        result = Accounting.formatNumber(fee)+' sats'
      }
      
      return result
    }
  }
}
</script>

<style lang="scss" scoped>

.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--front);
  border: 2px solid var(--back);
  padding: 20px 23px;
  border-radius: 15px;
  width: 300px;
  height: 300px;
  transform-origin: center top;
  position: relative;
  transition: all 250ms $ease;

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7),
  &:nth-child(8),
  &:nth-child(9),
  &:nth-child(10) {
    position: absolute;
    left: 50%;
  }

  .height {
    width: 100%;

    p {
      color: var(--back);

      &:first-child {
        font-size: 16px;
      }

      &:nth-child(2) {
        b {
          font-size: 48px;
          line-height: 1;
          font-weight: 300;
        }
      }
    }
  }

  ol {
    width: 100%;

    li {
      padding-top: 7px;
      padding-bottom: 7px;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 18px;
        color: var(--back);

        &:first-child {
          font-weight: 500;
        }

        &:nth-child(2) {
          text-align: right;
          font-weight: 300;
        }
      }

      & + li {
        border-top: 1px solid var(--neutral-7);
      }
    }
  }
}

</style>
