<template>
  <div :class="classObject">
    <div class="clock">
      <div
        v-for="n in 60"
        :key="n"
        :class="'tick tick-' + n"
        :style="{ transform: 'rotate(' + n*6 + 'deg)' }"
      ><span /></div>
      <div class="hour" :style="hourStyle" ><span /></div>
      <div class="minute" :style="minuteStyle" ><span /></div>
    </div>
    <h4>{{ cityName }}</h4>
    <p>{{ formattedPrice }}</p>
  </div>
</template>

<script>
import SiteData from '@/data/SiteData.js'

import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

export default {
  name: 'GlobalPrices',

  components: [
    SiteData,
    Tools
  ],
  
  props: [
    'currency',
    'info',
    'showSats',
    'now'
  ],

  data: function() {
    return {
      width: 100,
      height: 100
    }
  },

  computed: {
    classObject() {
      const c = ['global-prices-clock']

      if(this.hours < 6 || this.hours > 19) {
        c.push('-night')
      }

      return c.join(' ')
    },

    cityName() {
      return SiteData.currencies[this.currency[0]].title
    },

    formattedPrice: function() {
      let result = null

      const priceData = Tools.op(this.info, 'ticker.'+this.currency[0])
      if(priceData) {
        const format = new Intl.NumberFormat(
          this.currency[1], 
          {
            style: 'currency', 
            currency: this.currency[0]
          }
        )

        let price = priceData.price

        if(this.showSats) {
          const currencyData = SiteData.currencies[this.currency[0]]
          price /= 100000000
          result = Tools.template(currencyData.template, {
            amount: Accounting.formatTinyAmount(price)
          })
        } else {
          result = format.format(price)
        }
      }

      return result;
    },

    hours: function() {
      const currencyData = SiteData.currencies[this.currency[0]]
      const nowCopy = this.now.setZone(currencyData.zone)
      return nowCopy.toFormat('H')
    },

    hourStyle: function() {
      const hours = this.hours % 12
      const minutes = this.minutes
      const angle = (hours + minutes/60) / 12 * 360 + 90

      return {
        transform: 'rotate(' + angle + 'deg)'
      };
    },

    minutes: function() {
      const currencyData = SiteData.currencies[this.currency[0]]
      const nowCopy = this.now.setZone(currencyData.zone)
      return nowCopy.toFormat('m')
    },

    minuteStyle: function() {
      const hours = this.minutes % 60;
      const angle = hours / 60 * 360 + 90;

      return {
        transform: 'rotate(' + angle + 'deg)'
      };
    }
  }
}
</script>

<style lang="scss" scoped>

.global-prices-clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 35%;
  flex-grow: 1;

  .clock {
    width: 150px;
    height: 150px;
    border: 1px solid var(--neutral-4);
    border-radius: 100px;
    position: relative;
    transition: all 150ms $ease;

    .minute {
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: calc(50% - 1px);
      transform-origin: center center;
      transform: rotate(-12deg);
      transition: all 150ms $ease;

      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        // border: 1px solid blue;

        &:before {
          display: block;
          content: '';
          position: absolute;
          background-color: var(--front);
          border-radius: 10px;
          height: 100%;
          width: 70px;
          top: 0;
          left: 7px;
        }
      }
    }

    .hour {
      position: absolute;
      width: 100%;
      height: 6px;
      left: 0;
      top: calc(50% - 3px);
      transform-origin: center center;
      transform: rotate(37deg);
      transition: all 150ms $ease;

      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        // border: 1px solid blue;

        &:before {
          display: block;
          content: '';
          position: absolute;
          background-color: var(--front);
          border-radius: 10px;
          height: 100%;
          width: 60px;
          top: 0;
          left: 17px;
        }
      }
    }

    .tick {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: 50%;
      transform-origin: center center;

      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        // border: 1px solid blue;

        &:before {
          display: block;
          content: '';
          position: absolute;
          background-color: var(--front);
          height: 100%;
          width: 3px;
          top: 0;
          left: 4px;
        }
      }
      
      &:nth-of-type(5n) span:before { width: 8px; }

      // &:nth-of-type(1) { transform: rotate(-0deg); }
      // &:nth-of-type(2) { transform: rotate(15deg); }
      // &:nth-of-type(3) { transform: rotate(30deg); }
      // &:nth-of-type(4) { transform: rotate(45deg); }
      // &:nth-of-type(5) { transform: rotate(60deg); }
      // &:nth-of-type(6) { transform: rotate(75deg); }
      // &:nth-of-type(7) { transform: rotate(90deg); }
      // &:nth-of-type(8) { transform: rotate(105deg); }
      // &:nth-of-type(9) { transform: rotate(120deg); }
      // &:nth-of-type(10) { transform: rotate(135deg); }
      // &:nth-of-type(11) { transform: rotate(150deg); }
      // &:nth-of-type(12) { transform: rotate(165deg); }
    }
  }

  h4 {
    margin-top: 20px;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: var(--front);
    transition: color 150ms $ease;
  }

  p {
    font-size: 16px;
    text-align: center;
    color: var(--neutral-7);
    transition: color 150ms $ease;
  }

  &.-night {
    .clock {
      background-color: var(--front);
      border-color: transparent;

      .hour,
      .minute,
      .tick {
        span {
          &:before {
            background-color: var(--back);
          }
        }
      }
    }
  }
}

</style>