<template>
  <div class="small-info-box">
    <img
      class="icon"
      :src="iconFile"
      width="60"
      height="60"
    />
    <h3>{{ title }}</h3>
    <p>{{ stat }}</p>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'SmallInfoBox',

  props: [
    'icon',
    'title',
    'description',
    'stat'
  ],

  computed: {
    iconFile() {
      return '/assets/icons/'+this.icon+'.svg'
    }
  }
}
</script>

<style lang="scss" scoped>

.small-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 60px;
    height: 60px;
  }

  h3 {
    margin: 10px 0 0 0;
    font-size: 12px;
    color: var(--neutral-7);
    text-transform: uppercase;
    text-align: center;
  }
  
  p {
    text-align: center;

    &:nth-of-type(1) {
      margin: 0 0 0 0;
      font-size: 24px;
      color: var(--front);
      font-weight: 600;
    }

    &:nth-of-type(2) {
      margin: 5px 0 0 0;
      font-size: 14px;
      color: var(--neutral-6);
      max-width: 200px;
      text-align: center;
    }
  }
}

</style>
