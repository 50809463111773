<template>
  <div class="transactions">
    <SectionTitle
      tag="Transactions"
      :title="title"
    />

    <div class="content">
      <div class="stats">
        <SmallInfoBox
          icon="average-fee"
          title="Average transaction Fee"
          description="The number of addresses that participated in transfers today."
          :stat="averageTransactionFee"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import SmallInfoBox from '@/components/SmallInfoBox.vue'

export default {
  name: 'Transactions',
  
  components: {
    SectionTitle,
    SmallInfoBox
  },

  props: [
    'info'
  ],

  computed: {
    title() {
      const count = Tools.op(this.info, 'stats.transactions_24h')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount+' transactions were made today.'
    },

    averageTransactionFee() {
      const count = Tools.op(this.info, 'stats.average_transaction_fee_24h')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount+' SATS'
    }
  }
}
</script>

<style lang="scss" scoped>

.transactions {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include r('padding', 20, 40); 
  gap: 20px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }
}

</style>
