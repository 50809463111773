<template>
  <div class="bars">
    <Bar
      v-for="(item, index) in barData"
      :key="index"
      :info="item"
      :minCount="minCount"
      :maxCount="maxCount"
    />
  </div>
</template>

<script>
import Bar from '@/components/value-transferred/Bar.vue'

export default {
  name: 'Bars',
  
  components: {
    Bar
  },

  props: [
    'info',
    'daily'
  ],

  data() {
    const barData = []

    let i=0, item
    const total = 30
    for(let day in this.daily) {
      item = this.daily[day]

      barData.push({
        count: item.bp,
        date: day,
        index: total - i - 1
      })

      i++
    }

    let maxCount, minCount, count
    for(let i=0; i<barData.length; i++) {
      count = barData[i]

      if(i==0 || count.count > maxCount) {
        maxCount = count.count
      }

      if(i==0 || count.count < minCount) {
        minCount = count.count
      }
    }

    return {
      barData,
      maxCount,
      minCount
    }
  }
}
</script>

<style lang="scss" scoped>

.bars {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;

  @include media-query(medium-down) {
    flex-basis: 200px;
  }

  @include media-query(large) {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

</style>
