<template>
  <div class="lightning-stats">
    <SectionTitle
      tag="Lightning"
      :title="title"
      :description="description"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="content">
      <div class="stats">
        <SmallInfoBox
          icon="ln-capacity"
          title="Median base fee"
          description="The base fee for transactions."
          :stat="baseFee"
        />
        <SmallInfoBox
          icon="ln-capacity"
          title="Median fee rate"
          description="Additional fee based on transaction amount."
          :stat="feeRate"
        />
        <SmallInfoBox
          v-if="false"
          icon="ln-capacity"
          title="Network capacity"
          description="The number of bitcoin locked in the Lightning&nbsp;network."
          :stat="networkCapacity"
        />
        <SmallInfoBox
          icon="ln-channels"
          title="Channel count"
          description="The number of public channels between&nbsp;nodes."
          :stat="numberOfChannels"
        />
        <SmallInfoBox
          icon="ln-new-nodes"
          title="Node capacity"
          description="The average amount of bitcoin per&nbsp;node."
          :stat="nodeCapacity"
        />
        <SmallInfoBox
          v-if="false"
          icon="ln-new-nodes"
          title="New nodes"
          description="New nodes joined the network&nbsp;today."
          :stat="newNodes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import SmallInfoBox from '@/components/SmallInfoBox.vue'
import SectionTip from '@/components/SectionTip.vue'

export default {
  name: 'Lightning stats',
  
  components: {
    SectionTitle,
    SmallInfoBox,
    SectionTip
  },

  props: [
    'info',
    'daily'
  ],

  data() {
    const sectionTip = {
      title: 'The lightning network',
      description: `
        <p>The lightning network is a second network that is tightly integrated with the bitcoin network. It serves as a scaling solution, by allowing for transactions to be negotiated outside the bitcoin network.</p>
      `,
      links: [
        {
          name: 'Bitcoin.design tech primer',
          url: 'https://bitcoin.design/guide/getting-started/technology-primer/#the-lightning-payment-network'
        },
        {
          name: 'Mastering the Lightning Network',
          url: 'https://github.com/lnbook/lnbook'
        }
      ]
    }

    return {
      sectionTip
    }
  },

  computed: {
    title() {
      const count = Tools.op(this.info, 'lightning.numberofnodes')
      const formattedCount = Accounting.formatNumber(count)

      return 'There are '+formattedCount+' public nodes on the Lightning network.'
    },

    description() {
      const count = Tools.op(this.info, 'lightning.numberofnodes30dchange')
      const formattedCount = Math.round(count*100)/100+'%'

      let result = 'That is an increase of '+formattedCount+' over the last 30 days.'

      if(count < 0) {
        result = 'That is a decrease of '+formattedCount+' over the last 30 days.'
      }

      return result
    },

    baseFee() {
      const count = Tools.op(this.info, 'lightning.medianbasefee')
      const formattedCount = Math.round(count * 100)/100

      return formattedCount + ' sats'
    },

    feeRate() {
      const count = Tools.op(this.info, 'lightning.medianfeerate')
      const formattedCount = Math.round(count * 1000000)/1000000*100

      return formattedCount + '%'
    },

    networkCapacity() {
      const count = Tools.op(this.info, 'lightning.networkcapacity')
      const formattedCount = Accounting.formatNumber(count / 100000000)

      return formattedCount
    },

    numberOfChannels() {
      const count = Tools.op(this.info, 'lightning.numberofchannels')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount
    },

    newNodes() {
      const count = Tools.op(this.info, 'lightning.newnodes24h')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount
    },

    nodeCapacity() {
      const count = Tools.op(this.info, 'lightning.averagenodecapacity')
      const formattedCount = Math.round(count * 100) / 100 + ' bitcoin'

      return formattedCount
    }
  },

  methods: {
    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.lightning-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;
    
  .stats {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    gap: 20px;

    > div {
      flex-grow: 1;
    }
  }

  @include media-query(medium-down) {
    .stats {
      > div {
        flex-basis: 40%;
        flex-grow: 1;
      }
    }
  }

  @include media-query(large) {
    .stats {
      > div {
        flex-basis: 40%;
        flex-grow: 1;
      }
    }
  }
}

</style>
