<template>
  <div class="price-graph">
    <SectionTitle
      tag="Price"
      :title="title"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="content">
      <div class="stats">
        <ChangeStat title="7-day change" amount="4.7" />
        <ChangeStat title="30-day change" amount="-21.57" />
      </div>
      <Graph :daily="daily" />
      <Legend :daily="daily" />
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'

import SectionTitle from '@/components/SectionTitle.vue'
import Graph from '@/components/price-graph/Graph.vue'
import ChangeStat from '@/components/price-graph/ChangeStat.vue'
import Legend from '@/components/price-graph/Legend.vue'
import SectionTip from '@/components/SectionTip.vue'

export default {
  name: 'PriceGraph',
  
  components: {
    SectionTitle,
    Graph,
    ChangeStat,
    Legend,
    SectionTip
  },

  props: [
    'info',
    'daily'
  ],

  data() {
    const sectionTip = {
      title: 'Price fluctuations',
      description: `
        <p>Add a description...</p>
      `
    }

    return {
      sectionTip
    }
  },

  computed: {
    title() {
      let amount = Tools.op(this.info, 'stats.market_price_usd_change_24h_percentage')
      amount = Math.round(amount*100)/100

      let result = 'Price is up today by <span class="-green">'+amount+'%</span>.'

      if(amount < 0) {
        result = 'Price is down today by <span class="-red">'+amount+'%</span>.'
      }

      return result
    }
  },

  methods: {
    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.price-graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    gap: 2px;

    .stats {
      display: flex;
      gap: 40px;
      padding-bottom: 30px;
    }
  }
}

</style>
