
module.exports = {

    formatNumber: function(amount, fixed) {
                // console.log('formatNumber', amount, fixed);
        return Number.parseFloat(amount).toFixed(fixed).replace(/./g, function(c, i, a) {
            return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
        });
    },

    formatMoney: function(amount, divide, fixed) {
        var result = amount/divide;

        if(!fixed && fixed !== 0) {
            fixed = 2;
        }

        result = result.toFixed(fixed).replace(/./g, function(c, i, a) {
            return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
        });

        switch(divide) {
            case 1000:
                result += ' K';
                break;
            case 1000000:
                result += ' M';
                break;
            case 1000000000:
                result += ' B';
                break;
        }

        return result;
    },

    formatGillions: function(amount, long) {
        var result = amount;
        var unit = null;
        var fixed = 2;

        var units = [
            [1000000000000, 'T', 'Trillion'],
            [1000000000, 'B', 'Billion'],
            [1000000, 'M', 'Million'],
            [1000, 'K', 'Thousand']
        ];

        for(var i=0; i<units.length; i++) {
            if(amount >= units[i][0]) {
                unit = units[i];

                result = amount / unit[0];

                break;
            }
        }

        result = result.toFixed(fixed).replace(/./g, function(c, i, a) {
            return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
        });

        if(unit) {
            result += ' ' + (long ? unit[2] : unit[1]);
        }

        return result;
    },

    formatSatoshi: function(amount, fixed) {
        var result = '';

        if(!fixed) fixed = 4;

        var useSatoshi = amount < 100000;

        // console.log('accounting.formatBitcoin', amount, prependSymbol, useSatoshi);

        if(useSatoshi) {
            result = amount + ' Satoshi';
        } else {
            // result = Math.round(amount/100000000*100000)/100000 + ' Bitcoin';
            result = amount/100000000;

            if(fixed) {
                result = result.toFixed(fixed);
            }

            result += ' Bitcoin';
        }

        return result;
    },

    formatBitcoin: function(amount, prependSymbol, useSatoshi) {
        amount /= 100000000;

        var result = '';

        // console.log('accounting.formatBitcoin', amount, prependSymbol, useSatoshi);

        if(useSatoshi) {
            // if(amount < 0.0001) {
                result += Math.round(amount*100000000*10000)/10000;
                if(prependSymbol) {
                    result += ' Satoshi';
                }
            // } else {
            //     result += Math.round(amount*100000)/100000;
            //     if(prependSymbol) {
            //         result += ' Bitcoin';
            //     }
            // }
        } else {
            result += Math.round(amount*100000)/100000;
            if(prependSymbol) {
                result += ' Bitcoin';
            }
        }

        return result;
    },

    formatTinyAmount: function(amount) {
      const stringAmount = amount+''
      let i=2
      for(; i<stringAmount.length; i++) {
        if(stringAmount[i] !== '0') {
          break
        }
      }
  
      return Math.round(amount*Math.pow(10, i)) / Math.pow(10, i)      
    }
}