<template>
  <div class="value-transferred">
    <SectionTitle
      tag="Transfers"
      :title="title"
      :description="description"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="content">
      <h4>Transactions per day</h4>
      <Bars 
        :info="info" 
        :daily="daily" 
      />
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import Bars from '@/components/value-transferred/Bars.vue'
import SectionTip from '@/components/SectionTip.vue'

export default {
  name: 'ValueTransferred',
  
  components: {
    SectionTitle,
    Bars,
    SectionTip
  },

  props: [
    'info',
    'daily'
  ],

  data() {
    const sectionTip = {
      title: 'Value transferred',
      description: `
        <p>Add a description...</p>
      `
    }

    return {
      sectionTip
    }
  },

  computed: {
    title() {
      const count = Tools.op(this.info, 'secondary.estimated_btc_sent')
      const formattedCount = Accounting.formatNumber(count / 100000000)

      const txCount = Tools.op(this.info, 'stats.transactions_24h')
      const formattedTxCount = Accounting.formatNumber(txCount)

      return formattedCount+' bitcoin were transferred today in '+formattedTxCount+' transactions.'
    },

    description() {
      const count = Tools.op(this.info, 'secondary.estimated_transaction_volume_usd')
      const formattedCount = Accounting.formatNumber(count)

      return 'That’s equivalent to $'+formattedCount+'.'
    }
  },

  methods: {
    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.value-transferred {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    gap: 10px;

    h4 {
      width: 100%;
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      color: rgba(var(--frontRGB), 0.62);
    }
  }
}

</style>
