<template>
  <div class="section-title">
    <p class="tag">{{ tag }}</p>
    <h2 v-html="title" />
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',

  props: [
    'tag',
    'title',
    'description'
  ]
}
</script>

<style lang="scss" scoped>

.section-title {
  display: flex;
  flex-direction: column;
  align-items: center; 
  @include r('padding-left', 10, 20);  
  @include r('padding-right', 10, 20);

  p.tag {
    background-color: $primary;
    color: var(--back);
    font-size: 10px;
    border-radius: 2px;
    padding: 4px 8px 3px 8px;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 150ms $ease;
  }
  
  h2 {
    margin: 10px 0 0 0;
    color: var(--front);
    text-align: center;
    font-weight: 300;
    @include r('font-size', 24, 36);
    line-height: 1.2;
    max-width: 650px;
    transition: color 150ms $ease;

    &::v-deep span {

      &.-green {
        color: $green;
      }

      &.-red {
        color: $red;
      }

      &.-tip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dashed var(--neutral-5);
        cursor: pointer;

        &:after {
          opacity: 0;
          pointer-events: none;
          display: block;
          content: attr(title);
          background-color: var(--front);
          color: var(--back);
          padding: 4px 6px 5px 6px;
          border-radius: 2px;
          font-size: 13px;
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translate(-50%, 10px);
          white-space: nowrap;
          transition: all 150ms $ease;
        }

        &:hover {
          &:after {
            opacity: 1;
            transform: translate(-50%, 0px);
          }
        }
      }
    }
  }

  p.description {
    margin: 5px 0 0 0;
    text-align: center;
    font-size: 18px;
    color: var(--neutral-7);
  }
}

</style>
