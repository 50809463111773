<template>
  <div class="header">
    <h1><img
      src="/assets/logo.png" 
      srcset="/assets/logo.png 1x, /assets/logo@2x.png 2x" 
      width="40"
      height="40"
      alt="Blocktime.live logo"
    />Blocktime.live</h1>
    <p>Activity around the bitcoin and lightning networks.</p>

    <button
      v-html="themeIcon"
      alt="Toggle theme"
      @click="toggleTheme"
    />
  </div>
</template>

<script>
export default {
  name: 'Header',

  props: [
    'theme'
  ],

  computed: {
    logoSrc() {
      return './assets/logo.png'
    },

    themeIcon() {
      let result = '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.4335 16.9335L23.9284 17.0046L24.0235 16.3435L23.3623 16.4386L23.4335 16.9335ZM16.0665 9.56653L16.5614 9.6377L16.6565 8.97653L15.9954 9.07162L16.0665 9.56653ZM23.3623 16.4386C23.081 16.479 22.7931 16.5 22.5 16.5V17.5C22.8409 17.5 23.1763 17.4756 23.5046 17.4284L23.3623 16.4386ZM22.5 16.5C19.1863 16.5 16.5 13.8137 16.5 10.5H15.5C15.5 14.366 18.634 17.5 22.5 17.5V16.5ZM16.5 10.5C16.5 10.2069 16.521 9.91904 16.5614 9.6377L15.5716 9.49535C15.5244 9.82369 15.5 10.1591 15.5 10.5H16.5ZM11 16C11 12.9793 13.2327 10.4792 16.1377 10.0614L15.9954 9.07162C12.6052 9.55919 10 12.4749 10 16H11ZM17 22C13.6863 22 11 19.3137 11 16H10C10 19.866 13.134 23 17 23V22ZM22.9386 16.8623C22.5208 19.7673 20.0207 22 17 22V23C20.5251 23 23.4408 20.3948 23.9284 17.0046L22.9386 16.8623Z" fill="currentColor"/><path d="M7.5 11.75V13.25" stroke="currentColor" stroke-linecap="round"/><path d="M8.25 12.5H6.75" stroke="currentColor" stroke-linecap="round"/><path d="M10.5 6.5V8.5" stroke="currentColor" stroke-linecap="round"/><path d="M11.5 7.5H9.5" stroke="currentColor" stroke-linecap="round"/></svg>'

      if(this.theme == 'light') {
        result = '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 19C12.7944 19 11 17.2056 11 15C11 12.7944 12.7944 11 15 11C17.2056 11 19 12.7944 19 15C19 17.2056 17.2056 19 15 19Z" stroke="currentColor"/><path d="M15 6.5V8.5" stroke="currentColor" stroke-linecap="round"/><path d="M23.5 15H21.5" stroke="currentColor" stroke-linecap="round"/><path d="M8.5 15H6.5" stroke="currentColor" stroke-linecap="round"/><path d="M15 21.5V23.5" stroke="currentColor" stroke-linecap="round"/><path d="M19.5 10.5L21 9" stroke="currentColor" stroke-linecap="round"/><path d="M9 21L10.5 19.5" stroke="currentColor" stroke-linecap="round"/><path d="M9 9L10.5 10.5" stroke="currentColor" stroke-linecap="round"/><path d="M19.5 19.5L21 21" stroke="currentColor" stroke-linecap="round"/></svg>'
      }
      return result
    }
  },

  methods: {
    toggleTheme() {
      this.$emit('toggleTheme')
    }
  }
}
</script>

<style lang="scss" scoped>

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
    margin: 0;
    color: var(--front);
    @include r('font-size', 18, 24);
    font-weight: 500;

    span {
      display: block;
      background-color: $primary;
      border-radius: 100px;
      width: 30px;
      height: 30px;
    }
  }

  p {
    color: var(--neutral-7);

    a {
      color: var(--front);

      &:hover {
        color: $primary;
        border-bottom: 1px dashed $primary;
      }
    }
  }

  button {
    appearance: none;
    padding: 0;
    border-width: 0;
    background-color: transparent;

    &::v-deep svg {
      width: 40px;
      height: 40px;
      color: $primary;
    }
  }

  @include media-query(small) {
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    padding: 20px 20px;
    gap: 15px;

    h1 {
      order: 1;
    }

    p {
      order: 3;
      text-align: center;
    }

    button {
      position: absolute;
      right: 18px;
      top: 18px;
      order: 2;
    }
  }

  @include media-query(medium-up) {
    padding: 10px 20px;
    gap: 20px;
  }
}

</style>
