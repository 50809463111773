<template>
  <div class="world-map">
    <svg width="1968px" height="849px" viewBox="0 0 1968 849" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g :stroke="strokeColor" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
        <Country
          v-for="(item, index) in countries"
          :key="index"
          :content="item"
          :maxCount="maxCount"
          :activeCountryId="activeCountryId"
          :theme="theme"
          @select="selectCountry"
          @hover="hoverCountry"
          @unhover="unhoverCountry"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import Country from '@/components/nodes/Country.vue'
import WorldMapSVG from '@/components/nodes/WorldMapSVG.js'

export default {
  name: 'WorldMap',
  
  components: {
    Country
  },

  props: [
    'info',
    'legend',
    'theme',
    'selectedCountry',
    'hoveredCountry'
  ],

  data() {
    return {
      maxCount: 0,
      countries: []
    }
  },

  mounted() {
    this.prepareCountries()
  },

  computed: {
    activeCountryId: function() {
      let result = null;

      if(this.selectedCountry) {
          result = this.selectedCountry.id;
      } else if(this.hoveredCountry) {
          result = this.hoveredCountry.id;
      }

      return result;
    },

    strokeColor() {
      return this.theme == 'dark' ? '#000000' : '#ffffff'
    }
  },

  methods: {
    prepareCountries: function() {
      var result = [];
      var maxCount = 0;

      var bits = WorldMapSVG.map.split('<p');
      // console.log('bits', bits);
      var bit, country;
      for(var i=0; i<bits.length; i++) {
        bit = bits[i];

        country = this.prepareCountry('<p'+bit);

        if(country) {
          maxCount = Math.max(maxCount, country.count);

          result.push(country);
        }
      }

      this.maxCount = maxCount;
      this.countries = result;
    },

    prepareCountry: function(path) {
      var result;

      var id = this.findBit(path, "id");

      if(id) {
        var d = this.findBit(path, "d");
        var points = this.findBit(path, "points");

        var isPath = path.indexOf('<path') === 0;
        var type = isPath ? 'path' : 'polygon';

        // console.log('prepareCountry', id, this.info)

        var countryName = id
        var count = 0;
        if(countryName) {
          var stats = this.getCasesByCountry(countryName);

          if(!stats.success) {
            // console.log('No country stats for ', id, countryName);
          }

          if(stats) {
            count = stats.count;
          }
        } else {
          console.log('Could not find countryName for', id);
        }

        var color = this.legend[this.legend.length - 1].color;
        var darkColor = this.legend[this.legend.length - 1].darkColor;
        for(var i=0; i<this.legend.length; i++) {
          if(count < this.legend[i].max) {
            color = this.legend[i].color;
            darkColor = this.legend[i].darkColor;
            break;
          }
        }

        result = {
          id: id,
          countryName: countryName,
          type: type,
          path: path,
          isPath: isPath,
          d: d,
          points: points,
          count: count,
          color: color,
          darkColor: darkColor
        };

        // if(this.hoveredCountry && this.hoveredCountry.id == id) {
        //   this.hoveredCountry = result;
        // }

        // if(this.selectedCountry && this.selectedCountry.id == id) {
        //   this.selectedCountry = result;
        // }
      }

      // this.l('prepareCountry', id, result);

      return result;
    },

    getCasesByCountry: function(countryName) {
      const result = {
        success: false,
        count: 0
      }
      
      if(this.info[countryName]) {
        result.success = true
        result.count = this.info[countryName]
      }

      // console.log('getCasesByCountry', countryName, this.info[countryName]);

      return result
    },

    findBit: function(data, attribute) {
      let result;

      const index = data.indexOf(' '+attribute+'="');
      if(index !== -1) {
        let bit = data.substring(index + attribute.length + 3);
        const endIndex = bit.indexOf('"');
        bit = bit.substring(0, endIndex);

        result = bit;
      }

      return result;
    },

    selectCountry: function(data) {
      this.$emit('selectCountry', data)
    },

    hoverCountry: function(data) {
      this.$emit('hoverCountry', data)
    },

    unhoverCountry: function(data) {
      this.$emit('unhoverCountry', data)
    }
  }
}
</script>

<style lang="scss" scoped>

.world-map {
  svg {
    width: 100%;
    height: auto;
  }
}

</style>
