<template>
  <p>{{ title }}</p>
</template>

<script>
export default {
  name: 'LegendItem',

  props: [
    'day',
    'info'
  ],

  computed: {
    title: function() {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      const d = new Date(this.day)
      return months[d.getMonth()]+' '+d.getDate()
    },

    visible: function() {
      return this.day
    }
  }
}
</script>

<style lang="scss" scoped>

p {
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--neutral-7);
}

</style>
