<template>
  <div :class="classObject">
    <div class="bar-wrap">
      <div :style="barStyle" />
    </div>
    <div class="text">
      <p><b>{{ formattedAmount }}</b><br/>{{ formattedDate }}</p>
    </div>
  </div>
</template>

<script>
import Accounting from '@/general/Accounting.js'

export default {
  name: 'Bar',

  props: [
    'info',
    'minCount',
    'maxCount'
  ],

  computed: {
    classObject() {
      const c = ['bar']

      if(this.info.index % 5 == 0) {
        c.push('-show-text')
      }

      return c.join(' ')
    },

    barStyle() {
      return {
        height: Math.round(this.info.count / this.maxCount * 100)+'%'
      }
    },

    formattedAmount() {
      const formattedCount = Accounting.formatNumber(this.info.count)

      return formattedCount
    },

    formattedDate() {
      return this.info.date
    }
  }
}
</script>

<style lang="scss" scoped>

.bar {
  display: flex;
  flex-direction: column;
  flex-basis: 6px;

  .bar-wrap {
    background-color: rgba(var(--frontRGB), 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 20px;
    width: 6px;
    flex-basis: 100px;
    flex-grow: 1;

    div {
      width: 100%;
      background-color: $primary;
      border-radius: 20px;
    }
  }

  .text {
    position: relative;
    text-align: center;
    opacity: 0;
    margin-top: 8px;
    height: 34px;

    p {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      color: var(--neutral-6);
      line-height: 1.2;
      white-space: nowrap;

      b {
        font-weight: 400;
        color: var(--neutral-8);
      }
    }
  }

  &.-show-text {
    .text {
      opacity: 1;
    }
  }
}

</style>
