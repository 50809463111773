<template>
  <button v-if="false" class="section-tip" @click="select" title="Learn more">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="7.25" r="1.25" fill="currentColor"/><rect x="11" y="10" width="2" height="8" fill="currentColor"/><rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor"/></svg>
  </button>
</template>

<script>
export default {
  name: 'SectionTip',

  props: [
    'tag',
    'title',
    'description'
  ],

  methods: {
    select() {
      this.$emit('select')
    }
  }
}
</script>

<style lang="scss" scoped>

.section-tip {
  position: absolute;
  right: 10px;
  top: 10px;
  appearance: none;
  padding: 10px;
  border-width: 0;
  background-color: transparent;

  svg {
    color: var(--neutral-6);
  }

  &:hover {
    svg {
      color: $primary;
    }
  }
}

</style>
