<template>
  <p class="legend-item">
    <span :style="styleObject" />{{ info.name }}
  </p>
</template>

<script>
export default {
  name: 'LegendItem',

  props: [
    'info',
    'theme'
  ],

  computed: {
    styleObject: function() {
      const color = this.theme == 'dark' ? this.info.darkColor : this.info.color
      
      return {
        backgroundColor: color
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.legend-item {
  display: flex;
  align-items: center;
  gap: 6px;
  @include r('font-size', 14, 14);

  span {
    font-weight: normal;
    font-style: italic;
    padding-left: 5px;
    height: 17px;
    width: 17px;
    border-radius: 15px;
    vertical-align: middle;
  }
}

</style>
