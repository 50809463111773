<template>
  <div class="lightning">
    <SectionTitle
      tag="Lightning"
      :title="title"
      :description="description"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="content">
      <h4>Bitcoin supply model</h4>
      <Graph :daily="daily" />
      <Legend :daily="daily" />
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import SectionTip from '@/components/SectionTip.vue'

import Graph from '@/components/lightning/Graph.vue'
import Legend from '@/components/lightning/Legend.vue'

export default {
  name: 'Lightning',
  
  components: {
    SectionTitle,
    SectionTip,
    Graph,
    Legend
  },

  props: [
    'info',
    'daily'
  ],

  data() {
    const sectionTip = {
      title: 'The lightning network',
      description: `
        <p>The lightning network is a second network that is tightly integrated with the bitcoin network. It serves as a scaling solution, by allowing for transactions to be negotiated outside the bitcoin network.</p>
      `,
      links: [
        {
          name: 'Bitcoin.design tech primer',
          url: 'https://bitcoin.design/guide/getting-started/technology-primer/#the-lightning-payment-network'
        },
        {
          name: 'Mastering the Lightning Network',
          url: 'https://github.com/lnbook/lnbook'
        }
      ]
    }

    return {
      sectionTip
    }
  },

  computed: {
    title() {
      const count = Tools.op(this.info, 'lightning.networkcapacity')
      const formattedCount = Accounting.formatNumber(count / 100000000)

      return formattedCount+' bitcoin are locked in the lightning&nbsp;network.'

      // const count = Tools.op(this.info, 'lightning.numberofnodes')
      // const formattedCount = Accounting.formatNumber(count)

      // return 'There are '+formattedCount+' public nodes on the Lightning network.'
    },

    description() {
      const count = Tools.op(this.info, 'lightning.networkcapacity')
      // const formattedCount = Accounting.formatNumber(count / 100000000)

      const dollarPrice = Tools.op(this.info, 'ticker.USD.price')
      const formattedPrice = Accounting.formatNumber(dollarPrice * count / 100000000)

      return 'That is about $'+formattedPrice+'.'

      // const count = Tools.op(this.info, 'lightning.numberofnodes30dchange')
      // const formattedCount = Math.round(count*100)/100+'%'

      // let result = 'That is an increase of '+formattedCount+' over the last 30 days.'

      // if(count < 0) {
      //   result = 'That is a decrease of '+formattedCount+' over the last 30 days.'
      // }

      // return result
    },

    baseFee() {
      const count = Tools.op(this.info, 'lightning.medianbasefee')
      const formattedCount = Math.round(count * 100)/100

      return formattedCount + ' sats'
    },

    feeRate() {
      const count = Tools.op(this.info, 'lightning.medianfeerate')
      const formattedCount = Math.round(count * 1000000)/1000000*100

      return formattedCount + '%'
    },

    networkCapacity() {
      const count = Tools.op(this.info, 'lightning.networkcapacity')
      const formattedCount = Accounting.formatNumber(count / 100000000)

      return formattedCount
    },

    numberOfChannels() {
      const count = Tools.op(this.info, 'lightning.numberofchannels')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount
    },

    newNodes() {
      const count = Tools.op(this.info, 'lightning.newnodes24h')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount
    }
  },

  methods: {
    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.lightning {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    gap: 10px;

    h4 {
      width: 100%;
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      color: var(--neutral-7);
    }
  }
}

</style>
