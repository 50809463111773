<template>
  <div class="nodes">
    <SectionTitle
      tag="Nodes"
      :title="title"
      description="This estimate is on the lower-end of the actual number."
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="map">
      <h4>{{ mapTitle }}</h4>
      <WorldMap
        :info="info.nodes"
        :legend="legend"
        :theme="theme"
        :selectedCountry="selectedCountry"
        :hoveredCountry="hoveredCountry"
        @selectCountry="selectCountry"
        @hoverCountry="hoverCountry"
        @unhoverCountry="unhoverCountry"
      />
      <Legend
        :info="legend"
        :theme="theme"
      />
    </div>
  </div>
</template>

<script>
import SiteData from '@/data/SiteData.js'

import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import WorldMap from '@/components/nodes/WorldMap.vue'
import Legend from '@/components/nodes/Legend.vue'
import SectionTip from '@/components/SectionTip.vue'

export default {
  name: 'Nodes',
  
  components: {
    SectionTitle,
    WorldMap,
    Legend,
    SectionTip
  },

  props: [
    'info',
    'theme'
  ],

  data() {
    const sectionTip = {
      title: 'Bitcoin nodes',
      description: `
        <p>Nodes are simply computers that participate in the bitcoin network. They constantly exchange data with each other and verify its accuracy.</p>
      `
    }

    const lightColor = [239, 239, 239]
    const darkColor = [32, 32, 32]
    const primaryColor = [189, 149, 66]

    const legend = [
        {
            min: 0,
            max: 10,
            name: "0 - 10 nodes",
            color: Tools.colorizeNumber(0, lightColor, primaryColor),
            darkColor: Tools.colorizeNumber(0, darkColor, primaryColor)
        },
        {
            min: 10,
            max: 50,
            name: "10 - 50 nodes",
            color: Tools.colorizeNumber(0.25, lightColor, primaryColor),
            darkColor: Tools.colorizeNumber(0.25, darkColor, primaryColor)
        },
        {
            min: 100,
            max: 500,
            name: "100 - 500 nodes",
            color: Tools.colorizeNumber(0.5, lightColor, primaryColor),
            darkColor: Tools.colorizeNumber(0.5, darkColor, primaryColor)
        },
        {
            min: 500,
            name: "500+ nodes",
            color: Tools.colorizeNumber(1, lightColor, primaryColor),
            darkColor: Tools.colorizeNumber(1, darkColor, primaryColor)
        }
    ]

    return {
      legend,
      sectionTip,
      hoveredCountry: null,
      selectedCountry: null
    }
  },

  computed: {
    title() {
      const count = Tools.op(this.info, 'stats.nodes')
      const formattedCount = Accounting.formatNumber(count)

      return formattedCount+' known nodes verify transactions.'
    },

    mapTitle() {
      let result = 'Global node distribution'

      if(this.hoveredCountry) {
        const countryName = SiteData.countryAbbreviations[this.hoveredCountry.id]

        if(countryName) {
          const count = this.hoveredCountry.count
          result = countryName+': '+count+' node'+(count == 1 ? '' : 's')
        }
      }

      return result
    }
  },

  methods: {
    selectCountry: function(data) {
      if(this.selectedCountry && this.selectedCountry.id == data.id) {
        this.selectedCountry = null;
      } else {
        this.selectedCountry = data;
      }
    },

    hoverCountry: function(data) {
      this.hoveredCountry = data;
    },

    unhoverCountry: function(data) {
      if(this.hoveredCountry && this.hoveredCountry.id == data.id) {
        this.hoveredCountry = null;
      }
    },

    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.nodes {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include r('padding', 20, 40); 
  gap: 20px;
  position: relative;

  .map {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
    @include r('gap', 10, 20);
    gap: 10px;

    h4 {
      padding-left: 80px;
      width: 100%;
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      color: rgba(var(--frontRGB), 0.62);
    }
  }
}

</style>
