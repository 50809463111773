<template>
  <div class="block-clock">
    <SectionTitle
      tag="BlockClock"
      :title="title"
      description="That's the total number of blocks confirmed by the bitcoin network."
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="content">
      <div class="blocks">
        <Block
          v-for="(item, index) in blocks"
          :key="item.blockHeight"
          :info="item"
          :index="index"
          :activeIndex="activeIndex"
          @click="nextIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import Block from '@/components/block-clock/Block.vue'
import SectionTip from '@/components/SectionTip.vue'

export default {
  name: 'BlockClock',
  
  components: {
    SectionTitle,
    Block,
    SectionTip
  },

  props: [
    'info'
  ],

  data() {
    const sectionTip = {
      title: 'The block clock',
      description: `
        <p>Add a description...</p>
      `
    }

    return {
      activeIndex: 0,
      sectionTip
    }
  },

  computed: {
    title() {
      const block = this.blocks[0]
      const formattedBlock = Accounting.formatNumber(block.blockHeight)
      return 'The block time is '+formattedBlock+'.'
    },

    blocks() {
      const blocks = Tools.op(this.info, 'blocks')

      const result = []

      let block
      for(let blockHeight in blocks) {
        block = blocks[blockHeight]

        result.push({
          blockHeight: blockHeight,
          blockTime: block.time,
          transactions: block.tx,
          feeTotal: block.fee
        })
      }

      return result.reverse()
    }
  },

  methods: {
    nextIndex() {
      this.activeIndex++

      if(this.activeIndex >= this.blocks.length) {
        this.activeIndex = 0
      }
    },

    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.block-clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;

  .content {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .blocks {
      position: relative;
    }
  }

  @include media-query(small) {
    gap: 60px;
  }

  @include media-query(medium) {
    
  }

  @include media-query(large) {
    
  }
}

</style>
