<template>
  <div class="network-value">
    <SectionTitle
      tag="Network value"
      :title="title"
      :description="description"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <div class="content">
      <h4>Bitcoin supply model</h4>
      <SupplyGraph 
        :minDate="minDate" 
        :maxDate="maxDate"
      />
      <SupplyGraphLegend
        :minDate="minDate" 
        :maxDate="maxDate"
      />
    </div>
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import SupplyGraph from '@/components/network-value/SupplyGraph.vue'
import SupplyGraphLegend from '@/components/network-value/SupplyGraphLegend.vue'
import SectionTip from '@/components/SectionTip.vue'

export default {
  name: 'NetworkValue',
  
  components: {
    SectionTitle,
    SupplyGraph,
    SupplyGraphLegend,
    SectionTip
  },

  props: [
    'info'
  ],

  data() {
    const sectionTip = {
      title: 'Network value',
      description: `
        <p>Over 3,000,000 bitcoin (<a href="https://www.coinwire.com/new-study-by-chainalysis-says-about-23percent-of-lost-bitcoin-may-never-be-recovered" target="_blank" rel="nofollow noindex">source</a>) are considered inaccessible due to lost private keys, further reducing the effective supply.</p>
      `
    }

    return {
      minDate: 2009,
      maxDate: 2075,
      sectionTip
    }
  },

  computed: {
    title() {
      const satoshiSupply = Tools.op(this.info, 'stats.circulation')
      const bitcoinSupply = Math.round(satoshiSupply / 100000000)
      const formattedSupply = Accounting.formatNumber(bitcoinSupply)
      return 'There are '+formattedSupply+' bitcoin in circulation.'
    },

    description() {
      const marketCap = Tools.op(this.info, 'stats.market_cap_usd')
      const formattedValue = Accounting.formatNumber(marketCap);
      
      return 'All bitcoin combined are worth $'+formattedValue+'.'
    }
  },

  methods: {
    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.network-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    gap: 10px;

    h4 {
      width: 100%;
      text-align: left;
      font-size: 12px;
      text-transform: uppercase;
      color: var(--neutral-7);
    }
  }
}

</style>
