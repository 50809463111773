<template>
  <component
      :is="content.type"
      :id="content.id"
      :points="content.points"
      :d="content.d"
      :style="styleObject"
      @click="click"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
  ></component>
</template>

<script>
export default {
  name: 'Country',

  props: [
    'content',
    'maxCount',
    'activeCountryId',
    'theme'
  ],

  data: function() {
    return {
      log: !true
    };
  },

  computed: {
    fillColor: function() {
      let result = this.content.color

      if(this.theme == 'dark') {
        result = this.content.darkColor
      }

      if(this.activeCountryId == this.content.id) {
        result = this.theme == 'dark' ? '#FFFFFF' : '#000000'
      }

      return result
    },

    styleObject: function() {
      return {
        fill: this.fillColor
      };
    }
  },

  methods: {
    mouseEnter: function() {
      this.$emit('hover', this.content);
    },

    mouseLeave: function() {
      this.$emit('unhover', this.content);
    },

    click: function() {
      // this.$emit('select', this.content);
    }
  }
}
</script>

<style lang="scss" scoped>

.nodes {

}

</style>
