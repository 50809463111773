<template>
  <div class="global-prices-clocks">
    <Clock
      v-for="currency in currencies"
      :key="currency"
      :currency="currency"
      :info="info"
      :showSats="showSats"
      :now="now"
    />
  </div>
</template>

<script>
import Clock from '@/components/global-prices/Clock.vue'

export default {
  name: 'GlobalPrices',
  
  components: {
    Clock
  },

  props: [
    'info',
    'showSats',
    'now'
  ],

  data: function() {
    return {
      currencies: [
        ['USD', 'en-US'],
        ['EUR', 'de-DE'],
        ['JPY', 'jp-JP'],
        ['CNY', 'cn-CN']
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.global-prices-clocks {
  flex-grow: 1;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  gap: 40px;

  @include media-query(small) {
    flex-direction: column;
  }

  @include media-query(medium) {
    flex-wrap: wrap;
  }

  @include media-query(large) {
    
  }
}

</style>