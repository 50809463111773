<template>
  <Transition name="modal">
    <div class="section-tip-overlay" v-if="info">
      <div class="wrap" @click="clickBackground" ref="canvas">
        <div class="content">
          <button @click="close" title="Close">
            <svg 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M6 6L18 18" 
                stroke="currentColor" 
                stroke-linecap="round"
              />
              <path 
                d="M18 6L6.00001 18" 
                stroke="currentColor" 
                stroke-linecap="round"
              />
            </svg>
          </button>
          <h2 v-html="info.title" />
          <div 
            class="description" 
            v-if="info.description" 
            v-html="info.description"
          />
          <div
            class="links"
            v-if="info.links"
          >
            <p>Find out more:</p>
            <ul>
              <li 
                v-for="(item, index) in info.links"
                :key="index"
              >
                <a
                  :href="item.url"
                  target="_blank"
                  rel="nofollow noindex" 
                >{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'SectionTipOverlay',

  props: [
    'info'
  ],

  watch: {
    info: function() {
      if(this.info) {
        var top = -window.scrollY + 'px';
        document.body.style.position = 'fixed';
        document.body.style.top = top;
        // document.body.classList.add('-modal')
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        // document.body.classList.remove('-modal')
      }
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    clickBackground(event) {
      if(event.target == this.$refs.canvas) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.section-tip-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; 
  box-sizing: border-box;

  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--frontRGB), 0.5);
    width: 100%;
    height: 100%;
    position: relative;
    padding: 25px;

    .content {
      position: relative;
      background-color: var(--back);
      border-radius: 15px;
      padding: 25px;
      flex-basis: 600px;
      flex-shrink: 1;

      button {
        position: absolute;
        top: 10px;
        right: 10px;
        appearance: none;
        padding: 10px;
        border-width: 0;
        background-color: transparent;

        svg {
          color: var(--neutral-6);
        }

        &:hover {
          svg {
            color: $primary;
          }
        }
      }
      
      h2 {
        margin: 10px 0 0 0;
        color: var(--front);
        text-align: center;
        font-weight: 300;
        @include r('font-size', 24, 36);
        line-height: 1.2;
        max-width: 650px;
        transition: color 150ms $ease;
      }

      .description {
        margin-top: 15px;

        &::v-deep p {
          font-size: 18px;
          color: var(--neutral-7);
          line-height: 1.4;
        }
      }

      .links {
        margin: 10px 0 0 0;

        p {
          font-size: 18px;
          color: var(--neutral-7);
          line-height: 1.4;
        }

        ul {
          padding-left: 20px;
          
          li {
            list-style-type: disc;

            a {
              font-size: 18px;
              line-height: 1.4;

              &:hover {

              }
            }
          }
        }
      }
    }
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s $ease;

  .wrap {
    transition: all 0.5s $ease;
    
    .content {
      transition: all 0.5s $ease;
    }
  }
}

.modal-enter-from,
.modal-leave-to {
  .wrap {
    background-color: rgba(var(--frontRGB), 0);

    .content {
      opacity: 0;
      transform: translateY(50px);
    }
  }
}

</style>
