<template>
  <div class="main">
    <Header
      :theme="theme"
      @toggleTheme="toggleTheme"
    />
    <div class="sections" v-if="info && daily">
      <BlockClock 
        :info="info"
        @setSectionTip="setSectionTip"
      />
      <GlobalPrices 
        :info="info" 
        @setSectionTip="setSectionTip"
      />
      <PriceGraph 
        :info="info" 
        :daily="daily" 
        @setSectionTip="setSectionTip"
      />
      <ValueTransferred 
        :info="info" 
        :daily="daily" 
        @setSectionTip="setSectionTip"
      />
      <Miners 
        :info="info" 
        @setSectionTip="setSectionTip"
      />
      <NetworkValue 
        :info="info" 
        @setSectionTip="setSectionTip"
      />
      <Supply 
        :info="info" 
        @setSectionTip="setSectionTip"
      />
      <Transactions 
        v-if="false" 
        :info="info" 
        @setSectionTip="setSectionTip"
      />
      <Nodes 
        :info="info" 
        :theme="theme" 
        @setSectionTip="setSectionTip"
      />
      <Lightning 
        :info="info" 
        :daily="daily" 
        @setSectionTip="setSectionTip"
      />
      <LightningStats
        :info="info" 
        :daily="daily" 
        @setSectionTip="setSectionTip"
      />
    </div>

    <SectionTipOverlay
      :info="sectionTip"
      @close="closeSectionTipOverlay"
    />
    <Footer v-if="loaded" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import BlockClock from '@/components/block-clock/BlockClock.vue'
import GlobalPrices from '@/components/global-prices/GlobalPrices.vue'
import Transactions from '@/components/transactions/Transactions.vue'
import ValueTransferred from '@/components/value-transferred/ValueTransferred.vue'
import Supply from '@/components/supply/Supply.vue'
import Nodes from '@/components/nodes/Nodes.vue'
import Miners from '@/components/miners/Miners.vue'
import NetworkValue from '@/components/network-value/NetworkValue.vue'
import PriceGraph from '@/components/price-graph/PriceGraph.vue'
import Lightning from '@/components/lightning/Lightning.vue'
import LightningStats from '@/components/lightning-stats/LightningStats.vue'
import SectionTipOverlay from '@/components/SectionTipOverlay.vue'

export default {
  name: 'App',

  components: {
    Header,
    Footer,
    BlockClock,
    GlobalPrices,
    Transactions,
    ValueTransferred,
    Supply,
    PriceGraph,
    NetworkValue,
    Miners,
    Nodes,
    Lightning,
    LightningStats,
    SectionTipOverlay
  },

  data() {
    let theme = 'light'
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      theme = 'dark'
    }

    return {
      loaded: false,
      theme: theme,
      info: null,
      daily: null,
      timer: null,
      sectionTip: null
    }
  },

  beforeMount() {
    this.loadInfo()

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.onThemeChange.bind(this));
  },

  methods: {
    loadInfo() {
      clearTimeout(this.timer)
      const ref = this

      fetch('/data/data.json')
        .then(response => response.json())
        .then(data => {
          ref.info = data
          ref.loadDaily()
        });
    },

    loadDaily() {
      const ref = this

      fetch('/data/daily.json')
        .then(response => response.json())
        .then(data => {
          ref.daily = data
          ref.startTimer()
        });
    },

    startTimer() {
      this.loaded = true
      
      clearTimeout(this.timer)
      this.timer = setTimeout(this.loadInfo.bind(this), 300000) // 300000 = 5 minutes
    },

    toggleTheme() {
      if(this.theme == 'light') {
        this.theme = 'dark'
        document.body.classList.add('-dark')
      } else {
        this.theme = 'light'
        document.body.classList.remove('-dark')
      }
    },

    onThemeChange(event) {
      this.theme = event.matches ? 'dark' : 'light'
    },

    setSectionTip(tip) {
      this.sectionTip = tip
    },

    closeSectionTipOverlay() {
      this.sectionTip = null
    }
  }
}
</script>

<style lang="scss">

@import "@/css/general/common.scss";
@import "@/css/general/normalize.scss";

.main {
  .sections {
    > div {
      
    }
  }

  @include media-query(medium-down) {
    .sections {
      > div {
        border-top: 1px solid var(--neutral-4);
      }
    }
  }

  @include media-query(large) {
    .sections {
      display: flex;
      flex-wrap: wrap;

      > div {
        flex-basis: 45%;
        flex-grow: 1;
        min-height: 600px;
        border-top: 1px solid var(--neutral-4);

        &:nth-child(2n + 1) {
          border-right: 1px solid var(--neutral-4);
        }
      }
    }
  }
}

</style>
