<template>
  <div class="global-prices">
    <SectionTitle
      tag="Global prices"
      :title="title"
      @click="toggleSats"
    />
    <SectionTip
      :tip="sectionTip"
      @click="showSectionTip"
    />
    <Clocks
      :info="info"
      :showSats="showSats" 
      :now="now"
    />
  </div>
</template>

<script>
import Tools from '@/general/Tools.js'
import Accounting from '@/general/Accounting.js'

import SectionTitle from '@/components/SectionTitle.vue'
import Clocks from '@/components/global-prices/Clocks.vue'
import SectionTip from '@/components/SectionTip.vue'

import { DateTime } from "luxon"

export default {
  name: 'GlobalPrices',
  
  components: {
    SectionTitle,
    Clocks,
    SectionTip
  },

  props: [
    'info'
  ],

  data() {
    const sectionTip = {
      title: 'Global prices',
      description: `
        <p>Add a description...</p>
      `
    }

    return {
      showSats: false,
      now: DateTime.local(),
      timer: null,
      sectionTip
    }
  },

  computed: {
    title() {
      const dollarPrice = Tools.op(this.info, 'ticker.USD.price')
      const formattedPrice = Accounting.formatNumber(dollarPrice)

      let result = 'The price of 1 <span class="-tip" title="Switch to satoshis">bitcoin</span> is $'+formattedPrice+'.'

      if(this.showSats) {
        const formattedAmount = Accounting.formatTinyAmount(dollarPrice/100000000)
        result = 'The price of 1 <span class="-tip" title="Switch to bitcoin">satoshi</span> is $'+formattedAmount+'.'
      }

      return result
    }
  },

  mounted() {
    this.timer = setInterval(this.tick.bind(this), 60000)
  },

  methods: {
    tick() {
      this.now = DateTime.local()
    },

    toggleSats() {
      this.showSats = !this.showSats
    },

    showSectionTip() {
      this.$emit('setSectionTip', this.sectionTip)
    }
  }
}
</script>

<style lang="scss" scoped>

.global-prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  @include r('padding-left', 20, 40); 
  @include r('padding-right', 20, 40); 
  gap: 20px;
  position: relative;
}

</style>
