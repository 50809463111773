<template>
  <div class="legend">
    <LegendItem
        :info="firstItem"
        :day="firstDate"
    />
    <LegendItem
        :info="lastItem"
        :day="lastDate"
    />
  </div>
</template>

<script>
import LegendItem from '@/components/price-graph/LegendItem.vue'

export default {
  name: 'Legend',
  
  components: {
    LegendItem
  },

  props: [
    'daily'
  ],

  computed: {
    firstDate() {
      const keys = Object.keys(this.daily)
      return keys[0]
    },

    lastDate() {
      const keys = Object.keys(this.daily)
      return keys[keys.length-1]
    },

    firstItem() {
      return this.daily[this.firstDate]
    },

    lastItem() {
      return this.daily[this.lastDate]
    }
  }
}
</script>

<style lang="scss" scoped>

.legend {
  border-top: 1px solid var(--neutral-5);
  padding-top: 10px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
}

</style>
