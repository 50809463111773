<template>
  <div class="legend">
    <LegendItem
        v-for="(item, index) in info"
        :key="index"
        :info="item"
        :theme="theme"
    />
  </div>
</template>

<script>
import LegendItem from '@/components/nodes/LegendItem.vue'

export default {
  name: 'Legend',
  
  components: {
    LegendItem
  },

  props: [
    'info',
    'theme'
  ]
}
</script>

<style lang="scss" scoped>

.legend {
  display: flex;
  @include r('gap', 10, 30);
  justify-content: center;
}

</style>
