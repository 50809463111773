<template>
  <div :class="classObject">
    <div class="icon">
      <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4261 0.276152L13.4993 0.34494L20 6.99975L13.4981 13.6551C13.0489 14.1149 12.3205 14.115 11.8712 13.6552C11.4456 13.2196 11.4232 12.5276 11.804 12.065L11.8712 11.9901L15.5945 8.17712H1.15038C0.515041 8.17712 0 7.64997 0 6.99971C0 6.379 0.469284 5.87047 1.06452 5.82552L1.15038 5.8223H15.5957L11.8723 2.00988C11.4467 1.57423 11.4244 0.882214 11.8052 0.419656L11.8724 0.344771C12.2981 -0.0907916 12.9742 -0.113648 13.4261 0.276152Z" fill="currentColor"/>
      </svg>
    </div>
    <div class="text">
      <p>{{ title }}</p>
      <p>{{ formattedAmount }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChangeStat',

  props: [
    'amount',
    'title'
  ],

  computed: {
    classObject() {
      const c = ['change-stat']

      if(this.amount > 0) {
        c.push('-positive')
      } else if(this.amount < 0) {
        c.push('-negative')
      }

      return c.join(' ')
    },

    formattedAmount() {
      return (this.amount > 0 ? '+' : '')+this.amount+'%'
    }
  }
}
</script>

<style lang="scss" scoped>

.change-stat {
  display: flex;
  gap: 12px;

  .icon {
    background-color: var(--neutral-5);
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--back);
    }
  }

  .text {
    p {
      &:first-child {
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1.2;
      }

      &:nth-child(2) {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 300;
      }
    }
  }

  &.-positive {
    .icon {
      background-color: var(--green);

      svg {
        transform: rotate(-90deg);
      }
    }

    .text {
      p {
        &:nth-child(2) {
          color: var(--green);
        }
      }
    }
  }

  &.-negative {
    .icon {
      background-color: var(--red);

      svg {
        transform: rotate(90deg);
      }
    }

    .text {
      p {
        &:nth-child(2) {
          color: var(--red);
        }
      }
    }
  }
}

</style>
